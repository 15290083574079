import React, { useState } from "react";
import { FixedSizeList as List } from "react-window";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";
import UsersBodyItem from "./UsersBodyItem";
import styles from "./styles.module.scss";
import Checkbox from "../../../components/Checkbox/Checkbox";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import SecondLoader from "../../../components/SecondLoader/SecondLoader";
import ConfirmModal from "../../../components/Modals/ConfirmModal/ConfirmModal";
import CourseStatusModal from "../../../components/Modals/CourseStatusModal/CourseStatusModal";
import useHandleModal from "../../../hooks/dom/useHandleModal";
import { toast } from "react-toastify";
import ToastMsg from "../../../components/ToastMsg/ToastMsg";
import cn from "classnames";
import UserApi from "../../../utils/api/UserApi";
import {
   USER_STATUS_BANNED,
} from "../../../constants/user";

export const STUDENT_JUST_FIELDS_SORTING = {
   team: { orderBy: "team", orderToggle: true },
   progress: { orderBy: "progress", orderToggle: true },
   name: { orderBy: "first_name", orderToggle: true },
   department: { orderBy: "department", orderToggle: true },
   position: { orderBy: "position", orderToggle: true },
   was: { orderBy: "updated_at", orderToggle: true },
   status: { orderBy: "user_course_status", orderToggle: true },
}

const UsersTable = ({
   columns,
   data,
   tableFor,
   changeUserStatus,
   loading,
   deleteUserFromTable,
   refetchCourseUsers,
   orderBy,
   setOrderBy,
   order,
   setOrder,
   selectMode,
   onClickRow,
   selectedUserListChangeStatus,
   toggleSelectAllUsersInTable,
   lastUserRef,
   handleRoleChange
}) => {
   const [clickedUserId, setClickedUserId] = useState(null);
   const [clickedItem, setClickedItem] = useState(null);
   const [isChangeLoading, setIsChangeLoading] = useState(false);
   
   const t = useFindTranslationsByString();
   const confirmModal = useHandleModal();
   const handleStatusModal = useHandleModal();

   const onOpenStatusModal = (item, e) => {
      e.stopPropagation();
      handleStatusModal.open();
      setClickedItem(item);
   };

   const openConfirmModal = (userId) => {
      setClickedUserId(userId);
      confirmModal.open();
   };

   const onDeleteFromPlatform = async () => {
      setIsChangeLoading(true);

      const res = await new UserApi().updateUserStatus(clickedUserId, {
         new_status: USER_STATUS_BANNED,
      });

      if (res?.success?.message) {
         if (deleteUserFromTable) deleteUserFromTable(clickedUserId);
         changeUserStatus(clickedUserId, USER_STATUS_BANNED);
         refetchCourseUsers();
         toast(<ToastMsg text={res.success.message} />);
      }

      if (res?.error?.message) toast(<ToastMsg text={res?.error?.message} isError />);
      setIsChangeLoading(false);
      confirmModal.close();
   };

   const handleSort = (column) => {

      const columnMapping = STUDENT_JUST_FIELDS_SORTING[column];
      if (columnMapping) {
         const { orderBy, orderToggle } = columnMapping;
         setOrderBy(orderBy || "");
         if (orderToggle) {
            setOrder(order === "asc" ? "desc" : "asc");
         }
      }
   };

   if (data?.length === 0 && !loading) {
      return <EmptyBlock />;
   }
   const Row = ({ index, style }) => {
      const row = data[index];
      
      return (
         <div style={style} className={cn(styles.row,
             { [styles.row_selected]: selectedUserListChangeStatus.includes(row.id)},
             selectMode && styles.with_select
         )}>
            <UsersBodyItem
               tableFor={tableFor}
               row={row}
               columns={columns}
               index={index}
               openConfirmModal={openConfirmModal}
               onOpenStatusModal={onOpenStatusModal}
               lastItemRef={index === data?.length - 1 ? lastUserRef : null}
               selectMode={selectMode}
               onClickRow={onClickRow}
               selectedUserListChangeStatus={selectedUserListChangeStatus}
               handleRoleChange={handleRoleChange}
            />
         </div>
      );
   };

   return (
     <div className={styles.table_container}>
       <table className={styles.table}>
         <thead className={styles.thead}>
           <tr>
             {columns.map((column) => (
              <th
                 key={column}
                 onClick={() => handleSort(column)}
                 className={cn(
                   styles.header,
                   column === "checkbox" && styles.checkbox,
                     selectMode && styles.with_select,
                 )}
               >
                 {column === "checkbox" ? (
                   <div
                     onClick={toggleSelectAllUsersInTable}
                     className={styles.checbox}
                   >
                     <Checkbox
                       isChecked={
                         selectedUserListChangeStatus?.length === data?.length
                       }
                       title={selectedUserListChangeStatus?.length || ""}
                     />
                   </div>
                 ) : (
                   <>
                     <span>{t(column?.replace("_", " "))}</span>
                     <div className={styles.arrow_wrapper}>
                       <span
                         className={cn(
                           styles.arrow_top,
                           styles.arrow,
                           order === "asc" &&
                             STUDENT_JUST_FIELDS_SORTING[column]?.orderBy === orderBy &&
                             styles.active,
                         )}
                       ></span>
                       <span
                         className={cn(
                           styles.arrow_bottom,
                           styles.arrow,
                           order === "desc" &&
                             STUDENT_JUST_FIELDS_SORTING[column]?.orderBy === orderBy &&
                             styles.active,
                         )}
                       ></span>
                     </div>
                   </>
                 )}
               </th>
             ))}
             <th className={cn(styles.option_th,
                 selectMode && styles.with_select,
                 )}></th>
           </tr>
         </thead>
       </table>

       {!!data?.length && (
         <List
           height={window.innerHeight * 0.6}
           itemCount={data?.length}
           itemSize={48}
           width="100%"
         >
           {Row}
         </List>
       )}

       {data?.length === 0 && !loading && <EmptyBlock />}

       {loading && (
         <div className="default_loader_wrapper">
           <SecondLoader />
         </div>
       )}

       <ConfirmModal
         confirmButtonText={t("Block user")}
         onConfirm={onDeleteFromPlatform}
         onClose={confirmModal.close}
         isOpen={confirmModal.isActive}
         title={t("You sure you want to block the user?")}
         isLoading={isChangeLoading}
       />

       <CourseStatusModal
         onClose={handleStatusModal.close}
         isOpen={handleStatusModal.isActive}
         item={clickedItem}
         refetchCourseUsers={refetchCourseUsers}
       />
     </div>
   );
};

export default UsersTable;
