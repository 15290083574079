import React, { useState } from "react";
import dots from "../../../assets/images/symbols/three_dots_white.svg";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";
import Checkbox from "../../../components/Checkbox/Checkbox";
import useOptions from "../../../hooks/useOptions";
import styles from "./styles.module.scss";
import arrow from "../../../assets/images/symbols/Arrow - Down.svg";
import arrowDown from "../../../assets/images/symbols/arrow.svg";
import statusActiveImg from "../../../assets/images/symbols/arrow_green.svg";
import statusPausedImg from "../../../assets/images/paused_status.svg";
import statusDeletedImg from "../../../assets/images/deleted_status.svg";

import cn from "classnames";
import { ROLES_COLORS } from "../../../constants/notifications";
import {
   STUDENT_STATUS_ACTIVE,
   STUDENT_STATUS_DELETED,
   STUDENT_STATUS_PAUSED,
} from "../../../constants/courses";
import ProgressLine from "../../../components/ProgressLine/ProgressLine";
import useFindTranslationsByString from "../../../hooks/useFindTranslationsByString";

const UsersBodyItem = ({
   row,
   columns,
   index,
   tableFor,
   openConfirmModal,
   onOpenStatusModal,
   lastItemRef,
   selectMode,
   onClickRow,
   selectedUserListChangeStatus,
   handleRoleChange,
}) => {
   const { dropdownRef, dotsRef, isOptionsOpen, closeOptions, toggleOptions } =
      useOptions();

   const {
      dropdownRef: dropdownRefRoles,
      dotsRef: rolesOpenRef,
      isOptionsOpen: isRolesOpen,
      closeOptions: closeOptionsRoles,
      toggleOptions: toggleDropdown,
   } = useOptions();

   const t = useFindTranslationsByString();

   const redirectToUserProfileAdminView = (e, id) => {
      e.stopPropagation();
      window.open(`/users/profile/${id}`, "_blank");
   };

   const defineIcon = (status) => {
      if (status === STUDENT_STATUS_ACTIVE) {
         return statusActiveImg;
      } else if (status === STUDENT_STATUS_PAUSED) {
         return statusPausedImg;
      } else if (status === STUDENT_STATUS_DELETED) {
         return statusDeletedImg;
      } else {
         return statusActiveImg;
      }
   };

   const handleRowClick = () => {
      onClickRow(row);
   };

   const onOpenRoleDropdown = (e) => {
      e.stopPropagation();
   };

   return (
      <tr
         ref={lastItemRef}
         key={`row-${index}`}
         className={cn(
            index % 2 === 0 ? styles.rowOdd : "",
            styles.clickableEl
         )}
         onClick={handleRowClick}
      >
         {columns.map((column, columnIndex) => (
            <React.Fragment key={`cell-${index}-${columnIndex}`}>
               {column === "checkbox" && (
                  <td
                     className={cn(
                        styles.table_data,
                        column === "checkbox" && styles.checkbox
                     )}
                  >
                     <Checkbox
                        isChecked={selectedUserListChangeStatus?.some(
                           (sItem) => sItem?.id === row?.id
                        )}
                        isGrey
                     />
                  </td>
               )}

               {column === "team" && (
                  <td className={styles.table_data}>
                     <div className={styles.table_data_depart}>
                        <CustomTooltip id={row.id} text={row?.team} limit={8} />
                     </div>
                  </td>
               )}

               {column !== "checkbox" &&
                  column !== "status" &&
                  column !== "team" &&
                  column !== "progress" &&
                  column !== "role" && (
                     <td>
                        <div
                           className={cn(
                              styles.cellContent,
                              row[column] === "-" ? styles.centered : ""
                           )}
                        >
                           <CustomTooltip
                              id={row[column]}
                              limit={12}
                              text={
                                 row[column] !== "waiting" ? row[column] : ""
                              }
                           />
                        </div>
                     </td>
                  )}

               {column === "status" && (
                  <td className={styles.cellContent}>
                     <div
                        className={styles.actions}
                        onClick={(e) => onOpenStatusModal(row, e)}
                     >
                        <img
                           src={defineIcon(row?.status_on_course)}
                           alt="icon"
                        />
                        <img src={arrow} alt="" />
                     </div>
                  </td>
               )}

               {column === "role" && (
                  <td
                     onClick={(e) =>
                        row?.role !== "student" ? toggleDropdown(e, row.id) : {}
                     }
                     ref={rolesOpenRef}
                  >
                     <div
                        className={cn(
                           styles.cellContent,
                           row?.role !== "student" ? styles.fewRoles : "",
                           "noselect"
                        )}
                     >
                        <div
                           style={{
                              backgroundColor:
                                 ROLES_COLORS?.find(
                                    (c) =>
                                       c?.title?.toLowerCase() ===
                                       row[column]?.toLowerCase()
                                 )?.color || "#B079F0",
                           }}
                           className={styles.dotM}
                        ></div>
                        <span className={styles.roletxt}>{row[column]}</span>

                        {row?.role !== "student" && (
                           <img className={styles.arrowRole} src={arrowDown} />
                        )}
                     </div>

                     {isRolesOpen && (
                        <div
                           ref={dropdownRefRoles}
                           className={cn(styles.dropdownRoles, "noselect")}
                        >
                           <div
                              className={cn(
                                 styles.dropdownItem,
                                 row[column] === row?.roles[0]?.name
                                    ? styles.activeRole
                                    : ""
                              )}
                           >
                              <div
                                 className={cn(
                                    styles.dropdown_as_astudent,
                                    styles.roleItem
                                 )}
                              >
                                 <div
                                    style={{
                                       backgroundColor:
                                          ROLES_COLORS?.find(
                                             (c) =>
                                                c?.title?.toLowerCase() ===
                                                row[column]?.toLowerCase()
                                          )?.color || "#B079F0",
                                    }}
                                    className={styles.dotDM}
                                 ></div>
                                 <span className={styles.roleName}>
                                    {row[column]}
                                 </span>

                                 {row?.role !== "student" && (
                                    <img
                                       className={styles.arrowRoleUp}
                                       src={arrowDown}
                                    />
                                 )}
                              </div>

                              <div
                                 onClick={() =>
                                    handleRoleChange(row,  row?.roles[0]?.name === row[column])
                                 }
                                 className={cn(
                                    styles.roleItem,
                                    styles.roleItemFromRoles
                                 )}
                              >
                                 <div
                                    style={{
                                       backgroundColor:
                                          row?.roles[0]?.name === row[column]
                                             ? "#2c9c00"
                                             : ROLES_COLORS?.find(
                                                  (c) =>
                                                     c?.title?.toLowerCase() ===
                                                     row?.roles[0]?.name?.toLowerCase()
                                               )?.color || "#B079F0",
                                    }}
                                    className={styles.dotDM}
                                 ></div>
                                 <div className={styles.roleName}>
                                    {row?.roles[0]?.name === row[column]
                                       ? "As student"
                                       : row?.roles[0]?.name}
                                 </div>
                              </div>
                           </div>
                        </div>
                     )}
                  </td>
               )}

            {column === "progress" && (
               <td className={styles.cellContent}>
                  <ProgressLine progress={row?.progress} dashIsNotNeeded />
               </td>
               )
            }
            </React.Fragment>
         ))}

         {tableFor !== "requests" && tableFor !== "teams" && (
            <td
               className={cn(
                  styles.option_wrapper,
                  selectMode && styles.with_select
               )}
            >
               <img
                  ref={dotsRef}
                  onClick={(e) => {
                     e.stopPropagation();
                     toggleOptions();
                  }}
                  src={dots}
                  alt={"options"}
               />

               {isOptionsOpen &&
                  (tableFor === "students" || tableFor === "roles") && (
                     <div ref={dropdownRef} className={styles.dropdown}>
                        <p
                           className={styles.options}
                           onClick={(e) =>
                              redirectToUserProfileAdminView(e, row?.id)
                           }
                        >
                           {t("Profile")}
                        </p>

                        <p
                           className={styles.options}
                           onClick={(e) => {
                              e.stopPropagation();
                              closeOptions();
                              if (openConfirmModal) openConfirmModal(row.id);
                           }}
                        >
                           {t("Block user")}
                        </p>
                     </div>
                  )}
            </td>
         )}
      </tr>
   );
};

export default UsersBodyItem;
