import { useRef, useState } from "react";
import useOutsideClick from "./dom/useOutsideClick";

const useOptions = () => {
   const [isOptionsOpen, setIsOptioonsOpen] = useState(false);
   const dotsRef = useRef();
   const dropdownRef = useRef();

   const toggleOptions = (e) => {
      if(e) e.stopPropagation();
      setIsOptioonsOpen(!isOptionsOpen);
   };
   const closeOptions = () => setIsOptioonsOpen(false);

   useOutsideClick(dropdownRef, closeOptions, dotsRef);

   return {
      dropdownRef,
      dotsRef,
      isOptionsOpen,
      closeOptions,
      toggleOptions,
   };
};

export default useOptions;
