export const initialFiltersData = {
   leftChartCompanyFilter: null,
   testsCompanyFilter: null,
   questionsCompanyFilter: null,
   ratingCompanyFilter: null,
   certificateCompanyFilter: null,
   integrationCompanyFilter: null,
}

export const initialTeamFiltersData = {
   testsTeamFilter: null,
   questionsTeamFilter: null,
   ratingTeamFilter: null,
   certificateTeamFilter: null,
   integrationTeamFilter: null
}

export const initialCourseFiltersData = {
   testsCourseFilter: null,
   questionsCourseFilter: null,
   coursesCourseFilter: null,
   coursesProgressGraphFilter: null,
   usersCourseFilter: null
}

export const initialTotalCount = {
   questions: 0,
   tests: 0,
   users: 0,
   companies: 0,
   certificates: 0,
   courses: 0,
   courses_progress: 0,
   courses_progress_graph: 0
}

export const initialOrdersBy = {
   courses: '',
   coursesProgress: '',
   tests: '',
   questions: '',
   certificates: '',
   companies: '',
   users: '',
   integration: '',
}

export const initialOrders = {
   courses: 'asc',
   coursesProgress: 'asc',
   tests: 'asc',
   questions: 'asc',
   certificates: 'asc',
   companies: 'asc',
   users: 'asc',
   integration: 'asc',
}
  