import React from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

const Switch = ({ checked, onChange, title = null, disabled }) => {
   return (
      <div
         className={cn(styles.switch_wrapper, {
            [styles.disabled_wrapper]: disabled,
         })}
      >
         <div className={styles.switch} onClick={onChange}>
            <input
               id={title}
               type="checkbox"
               checked={checked}
               onChange={onChange}
               disabled={disabled}
            />
            <span className={`${styles.slider} ${styles.round}`}></span>
         </div>
         {title && (
            <label
               onClick={() => onChange(!checked)}
               htmlFor={title}
               className={cn(styles.title, {
                  [styles.disabled_text]: disabled,
               })}
            >
               {title}
            </label>
         )}
      </div>
   );
};

export default Switch;
