import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import SettingsBlock from "./SettingsBlock/SettingsBlock";
import Subheader from "./Subheader/Subheader";
import {
  COURSE_SETTINGS_SUBMENU,
  COURSE_STATUS_DELETED,
  COURSE_STATUS_HIDEN,
  COURSE_STATUS_ACTIVE,
  SUBMENU_ACCESS,
  SUBMENU_BASIC_SETTINGS,
  SUBMENU_CERTIFICATE,
  SUBMENU_END_COURSE,
} from "../../constants/courses";
import BasicSettingsTab from "./BasicSettingsTab/BasicSettingsTab";
import EndOfCourseTab from "./EndOfCourseTab/EndOfCourseTab";
import AccessToTheCourseTab from "./AccessToTheCourseTab/AccessToTheCourseTab";
import CourseApi from "../../utils/api/CourseApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ToastMsg from "../../components/ToastMsg/ToastMsg";
import moment from "moment";
import UserApi from "../../utils/api/UserApi";
import {
  PART_OF_MENTOR_NAME,
  ROLE_ID_STUDENT,
  USER_STATUS_ACTIVE,
} from "../../constants/user";
import { useSelector } from "react-redux";
import courseImg from "../../assets/images/course_thumbnail.png";
import EmptyBlock from "../../components/EmptyBlock/EmptyBlock";
import useDuplicateAgencies from "../../hooks/dom/useDuplicateAgencies";
import CertificateTab from "./CertificateTab/CertificateTab";
import CertificateApi from "../../utils/api/CertificateApi";
import useUserRole from "../../hooks/useUserRole";
import { sortRoles } from "../../utils/usersHelper";
import { returnTemporaryCourseLink } from "../../utils/coursesHelper";
import useGetActiveUsersOfCompany from "../../hooks/api/useGetActiveUsersOfCompany";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import useGetUsersOfCourse from "../../hooks/api/useGetUsersOfCourse";
import {
  COURSE_SETTINGS_GROUP,
  USER_EXTRA_GROUP_TEAM,
} from "../../utils/api/serialization_groups";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useGetTeams from "../../hooks/api/useGetTeamsOfCompany";
import useFindTranslationsByString from "../../hooks/useFindTranslationsByString";
import useHandleModal from "../../hooks/dom/useHandleModal";
import ConfirmModal from "../../components/Modals/ConfirmModal/ConfirmModal";
import { transformRoleText } from "../../utils/rolesHelper";
import StatisticsApi from "../../utils/api/StatisticsApi";

const CoursesSettingsPage = () => {
  const [activeTab, setActiveTab] = useState(SUBMENU_BASIC_SETTINGS);
  const [course, setCourse] = useState(null);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [courseImage, setCourseImage] = useState(null);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedPosition, setSelectedPosition] = useState(null);

  // Progress in change mass status and on add access to users.
  const [progress, setProgress] = useState(0);

  const confirmChangeStatusModal = useHandleModal();

  const [courseCurators, setCourseCurators] = useState([]);
  const [selectedUserList, setSelectedUserList] = useState([]);

    const [selectedCuratorsList, setSelectedCuratorsList] = useState([]);
    const [selectedTeamList, setSelectedTeamList] = useState([]);
    const [formData, setFormData] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [isChangeStatusesLoading, setIsChangeStatusesLoading] = useState(false);
    const [clickedStatusBtn, setClickedStatusBtn] = useState(false);
    const isCancelRequestsRef = useRef(null);
    const [selectedAsStudentRows, setSelectedAsStudentRows] = useState([]);

  const [courseProgressData, setCourseProgressData] = useState({
    completed: 0,
    ongoing: 0,
  });

  const getCourseProgressData = async () => {
    const res = await new StatisticsApi().getOneCourseProgressStatistics(
      params?.id,
    );

    if (res?.success?.data?.course?.[0]?.courseId) {
      setCourseProgressData({
        completed: res?.success?.data?.course?.[0]?.usersCompletedCourse,
        ongoing: res?.success?.data?.course?.[0]?.usersStartedCourse,
        total: res?.success?.data?.course?.[0]?.activeUsersWithAccess,
      });
    }
  };

  useEffect(() => {
    getCourseProgressData();
  }, []);

  const openConfirmChangeStatusesModal = (whatToDo) => {
    setClickedStatusBtn(whatToDo);
    confirmChangeStatusModal.open();
    isCancelRequestsRef.current = null;
  };

  const onCancelChangeSatatus = () => {
    isCancelRequestsRef.current = true;
    confirmChangeStatusModal.close();
  };

    const onClickRow = (row) => {
      if (!selectMode?.length) {
         window.open(`/statistics/2/${row?.id}/${params?.id}/${course?.name}`, '_blank');
         return;
      } 
   
      if (selectedUserListChangeStatus?.find(item => item?.id === row?.id)) {
         setSelectedUserListChangeStatus(selectedUserListChangeStatus?.filter(item => item?.id !== row?.id))
      } else setSelectedUserListChangeStatus([...selectedUserListChangeStatus, row])
    }

  const [currentSertificateTemplate, setCurrentSertificateTemplate] = useState({
    name: "first",
    type: "first_type_1",
    font_family: "Roboto",
    language: "en",
  });

  const companyId = useSelector((state) => state.sidebar.companyId);
  const companyName = useSelector((state) => state.sidebar.companyName);
  const userId = useSelector((state) => state?.user?.info?.id);
  const { userRoleId, userRoleName } = useUserRole(companyId);

  const params = useParams();
  const tagsInputRef = useRef(null);

  const { selectedAgencies, setSelectedAgencies, onDuplicateAgencies } =
    useDuplicateAgencies();

  const [selectMode, setSelectMode] = useState("");

  const changeSelectAllMode = (value) => {
    setSelectMode(value);
  };

  const agencyManager = useGetAgencies(userId);

  const lastAgencyRef = useIntersectionObserver(() => {
    if (
      !agencyManager.isAgenciesLoading &&
      agencyManager.agenciesList?.length < agencyManager.totalAgencies
    ) {
      if (agencyManager.agenciesList?.length === 0) {
        agencyManager.setCurrentPage(1);
      } else {
        agencyManager.setCurrentPage((prev) => prev + 1);
      }
    }
  }, [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]);

  const mapCuratorsList = (user) => ({
    ...user,
    name: `${user?.firstName || ""} ${user?.lastName || ""}`,
  });

  const fetchCourse = async () => {
    if (!params?.id) return;

    const res = await new CourseApi().getCourseByID(
      params?.id,
      COURSE_SETTINGS_GROUP,
    );

    if (res?.success?.data) {
      setCourse(res?.success?.data);

      const linkArray = await returnTemporaryCourseLink(
        userId,
        res?.success?.data,
      );

      if (linkArray?.[0]?.fileUrl) {
        setCourseImage(linkArray?.[0]?.fileUrl || courseImg);
      }
    }
  };

  const {
    setCurrentPage: setCurrentCourseUsersPage,
    userList: courseUserList,
    setUserList: setCourseUserList,
    searchedValue: courseSearchedValue,
    totalUsers: totalCourseUsers,
    setSearchedValue: setCourseSearchedValue,
    isUsersLoading: isCourseUsersLoading,
    refetchUsers: refetchCourseUsers,
    fetchUsers: fetchUsersOfCourse,
    orderBy,
    setOrderBy,
    order,
    setOrder,
    setSelectAll,
    selectAll,
    selectedUserListChangeStatus,
    setSelectedUserListChangeStatus,
  } = useGetUsersOfCourse(params?.id, USER_EXTRA_GROUP_TEAM);

  const lastCourseUserRef = useIntersectionObserver(() => {
    if (!isCourseUsersLoading && courseUserList?.length < totalCourseUsers) {
      if (courseUserList?.length === 0) {
        setCurrentCourseUsersPage(1);
      } else {
        setCurrentCourseUsersPage((prev) => prev + 1);
      }
    }
  }, [isCourseUsersLoading, totalCourseUsers]);

  const {
    setCurrentPage,
    userList: modalUsersList,
    setUserList: setModalUsersList,
    searchedValue: modalUserSearchedValue,
    totalUsers,
    setSearchedValue: setModalUserSearchedValue,
    isUsersLoading: isModalUsersLoading,
    selectAll: selectAllUsers,
    setSelectAll: setSelectAllUsers,
    emails,
    setEmails,
  } = useGetActiveUsersOfCompany(
    companyId,
    false,
    false,
    selectedDepartment?.id,
    selectedPosition?.id,
  );

  const lastModalUserRef = useIntersectionObserver(() => {
    if (!isModalUsersLoading && modalUsersList?.length < totalUsers) {
      if (modalUsersList?.length === 0) {
        setCurrentPage(1);
      } else {
        setCurrentPage((prev) => prev + 1);
      }
    }
  }, [isModalUsersLoading, totalUsers]);

  const {
    setCurrentPage: setCurrentSpecialUsersPage,
    userList: specialUsersList,
    isUsersLoading: isSpecialUsersLoading,
    totalUsers: totalSpecialUsers,
    searchedValue: specialUsersSearchedValue,
    setSearchedValue: setSpecialUsersSearchedValue,
    refetchUsers: refetchSpecialUsers,
  } = useGetActiveUsersOfCompany(companyId, false, true);

  const lastSpecialUserRef = useIntersectionObserver(() => {
    if (
      !isSpecialUsersLoading &&
      specialUsersList?.length < totalSpecialUsers
    ) {
      if (specialUsersList?.length === 0) {
        setCurrentSpecialUsersPage(1);
      } else {
        setCurrentSpecialUsersPage((prev) => prev + 1);
      }
    }
  }, [isSpecialUsersLoading, totalSpecialUsers]);

  const teamManager = useGetTeams({ companyId, userRoleName });

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!teamManager.isTeamsLoading && teamManager.teamsList?.length < teamManager.totalTeams) {
          if(teamManager.teamsList?.length === 0) {
            teamManager.setCurrentPage(1);
          } else {
            teamManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [teamManager.isTeamsLoading, teamManager.totalTeams]
    );
  
    const initCourseFormData = () => {
      setSelectMode('');
      setSelectedUserListChangeStatus([]);
      setSelectedAsStudentRows([]);

        if (course?.id) setFormData({
            new_status: +course?.status,
            //SUBMENU_BASIC_SETTINGS
            new_name: course?.name || '',
            new_description: course?.description || '',
            new_tags: course?.tags ? course?.tags : null,
            new_is_linear: course?.isLinear ? 1 : 0,
            new_is_progressive_validation_lock: course?.isProgressiveValidationLock ? 1 : 0,
            new_is_issue_certificate: +course?.isIssueCertificate,
            new_is_show_course_scores: +course?.isShowCourseScores,
            new_is_public: +course?.isPublic ? 1 : 0 ,
            new_is_export_in_pdf: +course?.isExportInPDF,
            new_is_detailed_course_completion: +course?.isDetailedCourseCompletion,
            new_percentage_to_receive_certificate: +course?.percentageToReceiveCertificate,
            new_global_test_try_count: +course?.globalTestTryCount,
            //SUBMENU_END_COURSE
            new_is_popup_end_button: +course?.isPopupEndButton,
            new_popup_title: course?.popupTitle || '',
            new_popup_text: course?.popupText || '',
            new_popup_text_on_button: course?.popupTextOnButton || '',
            new_popup_link: course?.popupLink || '',
            //SUBMENU_SELECT_DEADLINE
            new_is_deadline: +course?.isDeadline,
            new_is_flexible_deadline: +course?.isFlexibleDeadline,
            new_deadline_type: course?.deadlineType || [],
            new_deadline_date: course?.deadlineDate || '',
            new_deadline_date_dd: course?.new_deadline_date ? moment(course?.new_deadline_date).format('DD') : '',
            new_deadline_date_mm: course?.new_deadline_date ? moment(course?.new_deadline_date).format('MM') : '',
            new_deadline_date_yyyy: course?.new_deadline_date ? moment(course?.new_deadline_date).format('YYYY') : '',
        })

      setCurrentSertificateTemplate({
          ...currentSertificateTemplate,
          course_name: course?.name,
          color_palette: course?.certificateTemplate?.colorPalette || "1",
          name: course?.certificateTemplate?.name || "first",
          type: course?.certificateTemplate?.type || "first_type_1",
          language: course?.certificateTemplate?.language || "en",
          font_family: course?.certificateTemplate?.fontFamily || "en",
      })
  };

  const setDefaultCertificateTemplate = async () => {
    const data = {
      name: "first",
      language: "en",
      type: "first_type_1",
      color_palette: "1",
      font_family: "Roboto",
      template: "Initial",
    };

    const res = await new CertificateApi().createCertificateTemplate(data);

    if (res?.success?.data?.certificateTemplate) {
      const resEdit = await new CourseApi().editCourseSetting(params?.id, {
        new_certificate_template_id:
          res?.success?.data?.certificateTemplate?.id,
      });

      if (resEdit?.success) {
        fetchCourse();
      }
    }
  };

  const changeCertificateTemplate = async () => {
    const resEdit = await new CertificateApi().updateCertificateTemplate(
      course?.certificateTemplate?.id,
      {
        new_name: currentSertificateTemplate?.name,
        new_language: currentSertificateTemplate?.language,
        new_type: currentSertificateTemplate?.type,
        new_color_palette: currentSertificateTemplate?.color_palette,
        new_font_family: currentSertificateTemplate?.font_family,
      },
    );

    if (resEdit?.success) {
      fetchCourse();
    }
  };

  useEffect(() => {
    if (selectMode === "select_all") {
      setSelectAll(true);
      setSelectedUserListChangeStatus(courseUserList);
    } else {
      setSelectAll(false);
    }
  }, [selectMode]);

  const toggleSelectAllUsersInTable = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      setSelectedUserListChangeStatus(courseUserList);
    } else {
      setSelectedUserListChangeStatus([]);
    }
  };

  useEffect(() => {
    if (course?.id && !course.certificateTemplate) {
      setDefaultCertificateTemplate();
    }
  }, [course]);

  const removeEmptyFields = () => {
    const {
      new_deadline_type,
      new_deadline_date_dd,
      new_deadline_date_mm,
      new_deadline_date_yyyy,
      ...updatedFormData
    } = formData;

    const fieldsToExclude = [
      "new_deadline_type",
      "new_deadline_date_dd",
      "new_deadline_date_mm",
      "new_deadline_date_yyyy",
      "new_popup_text",
      "new_popup_title",
      "new_description",
    ];

    Object.keys(updatedFormData)?.forEach((field) => {
      if (field === "new_name") {
        updatedFormData[field] = updatedFormData[field].trim();
      }

      if (formData?.[field]?.length === 0 && !fieldsToExclude.includes(field)) {
        delete updatedFormData[field];
      }
    });

    return updatedFormData;
  };

  const refetchData = () => {
    refetchCourseUsers();
    refetchSpecialUsers();
    setSelectedUserList([]);
    getCourseProgressData();
    teamManager.clearAllTeamsFilters();
  };

  const onAssignUsersAsStudent = async () => {
    const markedUsers = modalUsersList?.filter((user) => user?.isAsStudent);
    if (!markedUsers?.length) return;

    const transformedData = markedUsers?.map((user) => ({
      user_id: user?.id,
      is_admin_as_student: user?.isAsStudent,
    }));

    const dataToSend = {
      users: transformedData,
    };
    const res = await new UserApi().changeAdminStatusInCourse(
      course?.id,
      dataToSend,
    );
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const addUserToCourseInChunks = async (users, status = 1) => {
    const userChunks = chunkArray(users, 25);
    const allAddUserPromises = [];

    let i = -1;
    for (const chunk of userChunks) {
      i++;

      if (isCancelRequestsRef.current) {
        break;
      }

      const data = {
        user_ids: chunk.map((user) => user?.id),
        status: status,
        ...(userChunks?.length > 1 ? { send_email: false } : {}),
        //  overwrite_user_course_active_or_paused_statuses: false
      };

      try {
        const res = await new CourseApi().addUsersToCourse(params?.id, data);

        if (res?.success?.message) {
          toast(<ToastMsg text={res?.success?.message} />);
        }

        if (res?.error?.message) {
          return toast(<ToastMsg text={res?.error?.message} isError />);
        }

        const newProgress = ((i + 1) / userChunks?.length) * 100;
        setProgress(newProgress?.toFixed());

        allAddUserPromises.push(res);
      } catch (error) {
        console.error(`Error adding users in chunk: ${error}`);
      }
    }

    return allAddUserPromises;
  };

  const onGiveAccess = async () => {
    setIsUsersLoading(true);
    if (selectedUserList.length) {
      const res = await addUserToCourseInChunks(selectedUserList);
      onAssignUsersAsStudent();
      if (res?.error?.message) {
        setIsUsersLoading(false);
        return toast(<ToastMsg text={res?.error?.message} isError />);
      }
    }

    if (selectedTeamList.length) {
      const resTeams = await new CourseApi().addTeamsToCourse(params?.id, {
        team_ids: selectedTeamList?.map((item) => item?.id),
        status: 1,
        send_email: false,
      });

      if (resTeams?.success?.message)
        toast(<ToastMsg text={resTeams?.success?.message} />);
      setIsUsersLoading(false);
      if (resTeams?.error?.message)
        return toast(<ToastMsg text={res?.error?.message} isError />);
    }

    setProgress(0);
    refetchData();
    fetchCourse();
    setIsUsersLoading(false);
  };

  const changeAdminAsStudentStatus = (clickedId, asWho) => {
    setModalUsersList((prevUsers) =>
      prevUsers.map((user) =>
        user.id === clickedId
          ? {
              ...user,
              isAsStudent: asWho === "student",
            }
          : user,
      ),
    );
  };

  const getCourseCurators = async () => {
    const res = await new UserApi().getCuratorsOfCourse(companyId, course?.id);
    if (res?.success?.data) {
      const curators = res?.success?.data?.map(mapCuratorsList);
      setCourseCurators(curators);
      setSelectedCuratorsList(curators);
    }
  };

  useEffect(() => {
    if (course?.id && companyId) {
      getCourseCurators();
    }
  }, [course, companyId]);

  const assignCurators = async (curators, isAdd) => {
    if (isAdd) {
      const res = await new CourseApi().addUsersToCourse(params?.id, {
        user_ids: curators?.map((item) => item?.id),
        status: USER_STATUS_ACTIVE,
      });

      if (res?.error?.message)
        toast(<ToastMsg text={res?.error?.message} isError />);
    }

    const resCurators = await new CourseApi().editCourseCurators(params?.id, {
      curator_data: curators?.map((item) => ({
        user_id: item?.id,
        add: isAdd,
      })),
    });

    if (resCurators?.error?.message) {
      toast(<ToastMsg text={resCurators?.error?.message} isError />);
    }

    refetchData();
    fetchCourse();
  };

  const changeMassStatuses = async () => {
    setIsChangeStatusesLoading(true);

    const status =
      clickedStatusBtn === "Delete"
        ? COURSE_STATUS_DELETED
        : clickedStatusBtn === "Active"
          ? COURSE_STATUS_ACTIVE
          : COURSE_STATUS_HIDEN;

    if (selectedUserListChangeStatus.length) {
      const res = await addUserToCourseInChunks(
        selectedUserListChangeStatus,
        status,
        true,
      );
      onAssignUsersAsStudent();
      if (res?.error?.message) {
        setIsUsersLoading(false);
        return toast(<ToastMsg text={res?.error?.message} isError />);
      }
    }

         confirmChangeStatusModal.close();
         setSelectedUserListChangeStatus([]);
         
         setProgress(0);
         setIsChangeStatusesLoading(false);
         refetchData();
         fetchCourse();
    }

    const onAssignUsersThatHaveAccessToTheCourseAsStudent = async () => {
      const transformedData = selectedAsStudentRows?.map(user => ({
         user_id: user?.id,
         is_admin_as_student: user?.is_as_student
      }))

      const dataToSend = {
           users: transformedData
         }
      const res = await new UserApi().changeAdminStatusInCourse(course?.id, dataToSend);
    }

  const onSave = async () => {
    if (!params?.id) return;

    if (activeTab === SUBMENU_CERTIFICATE) {
      changeCertificateTemplate();
    }

    const courseCuratorIDs = new Set(
      courseCurators.map((curator) => curator?.id),
    );
    const selectedCuratorIDs = new Set(
      selectedCuratorsList.map((curator) => curator.id),
    );

    // new curators
    const filteredCuratorsList = selectedCuratorsList?.filter(
      (curator) => !courseCuratorIDs?.has(curator?.id),
    );

    // curators to remove
    const curatorsThatNotInSelectedList = courseCurators.filter(
      (curator) => !selectedCuratorIDs.has(curator.id),
    );

    if (
      activeTab === SUBMENU_BASIC_SETTINGS &&
      (selectedCuratorsList?.length || curatorsThatNotInSelectedList?.length)
    ) {
      if (filteredCuratorsList?.length) {
        assignCurators(filteredCuratorsList, true);
      }

      if (curatorsThatNotInSelectedList?.length) {
        assignCurators(curatorsThatNotInSelectedList, false);
      }
    }

    if (activeTab === SUBMENU_BASIC_SETTINGS && selectedAgencies?.length) {
      onDuplicateAgencies(course?.id);
    }

    const updatedFormData = removeEmptyFields();

         const res = await new CourseApi().editCourseSetting(params?.id, updatedFormData);
          if (res?.error?.message) return toast(<ToastMsg text={res?.error?.message} isError/>)

          if(res?.success?.message) {
             toast(<ToastMsg text={res?.success?.message} />)
          }

          if(activeTab === SUBMENU_ACCESS && selectedAsStudentRows?.length) {
            await  onAssignUsersThatHaveAccessToTheCourseAsStudent();
            return;
         }

    fetchCourse();

    if(formData?.new_is_public) {
      refetchData();
    }
    if (!selectedAgencies.length)
      setActiveTab(activeTab === SUBMENU_ACCESS ? activeTab : activeTab + 1);
  };

  const onSaveImage = async (name) => {
    if (!params?.id) return;

    const res = await new CourseApi().editCourseImage(params?.id, {
      new_image_name: name,
    });

    if (res?.error?.message)
      return toast(<ToastMsg text={res?.error?.message} isError />);
    if (res?.success?.message) toast(<ToastMsg text={res?.success?.message} />);

    fetchCourse();
  };

  const onDeleteImage = async () => {
    if (!params?.id) return;

    const res = await new CourseApi().deleteCourseImage(params?.id);
    if (res?.error?.message)
      return toast(<ToastMsg text={res?.error?.message} isError />);
    fetchCourse();
    setCourseImage(null);
  };

  useEffect(() => {
    if (userId) fetchCourse();
  }, [userId]);

  useEffect(() => {
    initCourseFormData();
  }, [course]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeTab]);

  useEffect(() => {
    if (formData?.new_deadline_date) {
      const momentDeadlineDate = moment(formData?.new_deadline_date);
      const newDeadlineDateDD = momentDeadlineDate.format("D");
      const newDeadlineDateMM = momentDeadlineDate.format("M");
      const newDeadlineDateYYYY = momentDeadlineDate.format("YYYY");
      const formattedDeadlineDate = momentDeadlineDate.format("YYYY-MM-DD");

      setFormData({
        ...formData,
        new_deadline_date_dd: +newDeadlineDateDD,
        new_deadline_date_mm: +newDeadlineDateMM,
        new_deadline_date_yyyy: +newDeadlineDateYYYY,
        new_deadline_date: formattedDeadlineDate,
      });
    }
  }, [formData?.new_deadline_date]);

  const changeUserStatus = (userId, status) => {
    const userIndex = courseUserList?.findIndex((user) => user.id === userId);

    if (userIndex !== -1) {
      const updatedUserList = [...courseUserList];
      updatedUserList[userIndex] = {
        ...updatedUserList[userIndex],
        status: status,
      };

      setCourseUserList(updatedUserList);
    }
  };

    const deleteUserFromTable = (userId) => {
      const updatedUserList = courseUserList?.filter(user => user.id !== userId);
      setCourseUserList(updatedUserList);
    };


    const mappedCourseUsers = courseUserList?.map(user => {
      const userRoles = user?.userBranchRoles
            ?.filter(role => role?.role?.id === 1 || role?.branch?.company?.id === +companyId)
            ?.sort(sortRoles)
            ?.map(role => ({
               ...role?.role,
               name: transformRoleText(role?.role?.name, companyName)?.trim()
            }));      
   
      const userRole = userRoles?.[0];   
   
      const selectedUser = selectedAsStudentRows.find(selectedUser => selectedUser.id === user.id);
      const isNeedAsStudent = selectedUser?.id ? selectedUser?.is_as_student : user?.is_as_student;

      return {
         ...user,
         id: user?.id,
         team: user?.teams?.length ? user.teams.map(item => item.name).join(', ') : '-',
         department: user?.department ? user?.department : '-',
         name: user?.firstName || user?.lastName ? `${user?.firstName || ''} ${user?.lastName || ''}` : '-',
         position: user?.position ? user?.position : '-',
         was: user?.lastActivity ? moment(user?.lastActivity).format('DD.MM.YYYY') : '-',
         avatar: user?.avatar || '',
         role_id: userRole?.role?.id,
         role: isNeedAsStudent ? 'As student' : userRole?.name,
         roles: userRoles,
         is_as_student: isNeedAsStudent ? true : user?.is_as_student 
      };
   });

   const handleRoleChange = (row, isAsStudent) => {
      setSelectedAsStudentRows((prevRows) => {
         const existingRow = prevRows.find((r) => r.id === row.id);
   
         if (existingRow) {
            return prevRows.map((r) =>
               r.id === row.id ? { id: r.id, is_as_student: isAsStudent } : r
            );
         } else {
            return [...prevRows, { id: row?.id, is_as_student: isAsStudent }];
         }
      });
   };

  // Hide the page from students and mentors
  if (
    userRoleId === ROLE_ID_STUDENT ||
    userRoleName?.includes(PART_OF_MENTOR_NAME)
  ) {
    return (
      <MainLayout>
        <EmptyBlock text={"access to the page"} />
      </MainLayout>
    );
  }

  const t = useFindTranslationsByString();

  return (
    <MainLayout>
      <div className={styles.coursesSettingsPage}>
        <SettingsBlock
          courseImage={courseImage}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          course={course}
          data={formData}
          setData={setFormData}
          tagsInputRef={tagsInputRef}
          courseProgressData={courseProgressData}
        />
        <div className={styles.wrapper}>
          <Subheader
            title={t(COURSE_SETTINGS_SUBMENU?.find((item) => item?.value === activeTab)?.title)}
            text={t(COURSE_SETTINGS_SUBMENU?.find((item) => item?.value === activeTab)?.text)}
            onSave={onSave}
            onCancel={initCourseFormData}
            isChangeStatusesLoading={!course?.id}
          />

          <div className={styles.tab_wrapper}>
            {activeTab === SUBMENU_BASIC_SETTINGS && (
              <BasicSettingsTab
                courseId={params?.id}
                specialUsersSearchedValue={specialUsersSearchedValue}
                setSpecialUsersSearchedValue={setSpecialUsersSearchedValue}
                data={formData}
                specialUsers={specialUsersList}
                lastSpecialUserRef={lastSpecialUserRef}
                courseImage={courseImage}
                setCourseImage={setCourseImage}
                setData={setFormData}
                onSaveImage={onSaveImage}
                onDeleteImage={onDeleteImage}
                tagsInputRef={tagsInputRef}
                refetchData={refetchData}
                setSelectedCuratorsList={setSelectedCuratorsList}
                selectedCuratorsList={selectedCuratorsList}
                course={course}
                agenciesList={agencyManager?.agenciesList}
                selectedAgencies={selectedAgencies}
                setSelectedAgencies={setSelectedAgencies}
                setActiveTab={setActiveTab}
                lastAgencyRef={lastAgencyRef}
                fetchCourse={fetchCourse}
              />
            )}

            {activeTab === SUBMENU_CERTIFICATE && (
              <CertificateTab
                course={course}
                setData={setFormData}
                currentSertificateTemplate={currentSertificateTemplate}
                setCurrentSertificateTemplate={setCurrentSertificateTemplate}
              />
            )}

            {activeTab === SUBMENU_END_COURSE && (
              <EndOfCourseTab data={formData} setData={setFormData} />
            )}
            {/*

                        { FOR FUTURE
                            activeTab === SUBMENU_SELECT_DEADLINE
                            && <SelectDeadlineTab data={formData} setData={setFormData}/>
                        } */}

                        {
                            activeTab === SUBMENU_ACCESS
                            && <AccessToTheCourseTab
                                courseSearchedValue={courseSearchedValue}
                                setCourseSearchedValue={setCourseSearchedValue}
                                selectedUserList={selectedUserList}
                                setSelectedUserList={setSelectedUserList}
                                selectedTeamList={selectedTeamList}
                                setSelectedTeamList={setSelectedTeamList}
                                userList={modalUsersList} 
                                lastModalUserRef={lastModalUserRef}
                                modalUserSearchedValue={modalUserSearchedValue}
                                setModalUserSearchedValue={setModalUserSearchedValue}
                                courseUserList={mappedCourseUsers}
                                teamList={teamManager?.teamsList}
                                fetchTeams={teamManager?.refetchTeams}
                                course={course}
                                refetchData={refetchData}
                                lastCourseUserRef={lastCourseUserRef}
                                loading={isCourseUsersLoading}
                                isModalUsersLoading={isModalUsersLoading}
                                changeUserStatus={changeUserStatus}
                                deleteUserFromTable={deleteUserFromTable}
                                onGiveAccess={onGiveAccess}
                                changeAdminAsStudentStatus={changeAdminAsStudentStatus}
                                companyId={companyId}
                                isUsersLoading={isUsersLoading}
                                selectAllUsers={selectAllUsers}
                                setSelectAllUsers={setSelectAllUsers}
                                selectAllTeams={teamManager?.selectAll}
                                setSelectAllTeams={teamManager?.setSelectAll}
                                modalTeamSearchedValue={teamManager?.searchedValue}
                                setModaTeamrSearchedValue={teamManager.setSearchedValue}
                                lastTeamRef={lastTeamRef}
                                teamsLoading={teamManager?.isTeamsLoading}
                                fetchUsersOfCourse={fetchUsersOfCourse}
                                orderBy={orderBy}
                                setOrderBy={setOrderBy}
                                order={order}
                                setOrder={setOrder}
                                changeSelectAllMode={changeSelectAllMode}
                                selectedUserListChangeStatus={selectedUserListChangeStatus}
                                setSelectedUserListChangeStatus={setSelectedUserListChangeStatus}
                                selectMode={selectMode}
                                onClickRow={onClickRow}
                                openConfirmChangeStatusesModal={openConfirmChangeStatusesModal}
                                toggleSelectAllUsersInTable={toggleSelectAllUsersInTable}
                                emails={emails}
                                setEmails={setEmails}
                                progress={progress}
                                isCancelRequestsRef={isCancelRequestsRef}
                                selectedDepartment={selectedDepartment}
                                setSelectedDepartment={setSelectedDepartment}
                                selectedPosition={selectedPosition}
                                setSelectedPosition={setSelectedPosition}
                                handleRoleChange={handleRoleChange}
                            /> 
                        }

            <ConfirmModal
              isRemove={clickedStatusBtn === "Delete"}
              confirmButtonText={`${progress ? `${t("Loading")}... ${progress}%` : `${t("Confirm")}`}`}
              onConfirm={changeMassStatuses}
              onClose={confirmChangeStatusModal.close}
              isOpen={confirmChangeStatusModal.isActive}
              isLoading={isChangeStatusesLoading}
              title={t("Are you sure?")}
              subtitle={t("You can also change it in the future")}
              progressPercentage={progress}
              onCancel={onCancelChangeSatatus}
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default CoursesSettingsPage;
