import Switch from "../../../components/Switch/Switch";
import styles from "./styles.module.scss";
import cn from 'classnames';

const SwitchItem = ({ value, changeValue, title, text, disabled }) => {
   return (
      <div className={styles.switch_item}>
         <Switch title={title} onChange={changeValue} checked={value} disabled={disabled} />
         <p className={cn(styles.gray_text, disabled ? styles.disabled : '')}>{text}</p>
      </div>
   );
};

export default SwitchItem;
