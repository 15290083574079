import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import courseImg from "../../assets/images/course_thumbnail.png";
import cn from "classnames";
import styles from "./styles.module.scss";
import LineChart from "../../components/LineChart/LineChart";
import DropdownWithImg from "../../components/DropdownWithImg/DropdownWithImg";
import {
   CERTIFICATES_TABLE_HEADERS, COURSES_TABLE_HEADERS, COURSES_PROGRESS_TABLE_HEADERS, DATE_TAB_DAYLY, DEFAULT_DEBOUNCE_DELAY,
   INTEGRATION_TABLE_HEADERS,
   QUESTIONS_TABLE_HEADERS, RATING_TABLE_HEADERS, STATISTICS_USERS_TABLE_HEADERS, STAT_TAB_COMPANY, STATISTICS_USERS_TABLE_HEADERS_WITH_COURSE_FILTER,
   STAT_TAB_COURSES, STAT_TAB_SCORE, STAT_TAB_INTEGRUM, STAT_TAB_PLATFORM, STAT_TAB_USERS, TESTS_TABLE_HEADERS,
   certificatesMap, companiesMap, integrationMap, questionsMap, statisticsUsersMap, testsMap, coursesMap, coursesProgressMap, COURSE_PASSING_COLORS, 
   STATISTICS_TABS} from "../../constants/statistics";
import StatisticsTable from "./StatisticsTable/StatisticsTable";
import StatisticsSubheader from "./StatisticsSubheader/StatisticsSubheader";
import StatisticsTabs from "./StatisticsTabs/StatisticsTabs";
import CourseApi from "../../utils/api/CourseApi";
import StatisticsApi from "../../utils/api/StatisticsApi";
import SecondLoader from "../../components/SecondLoader/SecondLoader";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useGetYourCoursesInCompany from "../../hooks/api/useGetYourCoursesInCompany";
import useGetAgencies from "../../hooks/api/useGetAgencies";
import useGetAllTeams from "../../hooks/api/useGetAllTeams";
import { ROLE_ID_SUPER_ADMIN } from "../../constants/user";
import useUserRole from "../../hooks/useUserRole";
import useGetAllUsersOfCompany from "../../hooks/api/useGetAllUsersOfCompany";
import useFindTranslations from "../../hooks/useFindTranlsations";
import useGetStatisticsTests from "../../hooks/api/statistics/useGetStatisticsTests";
import useGetStatisticsUsers from "../../hooks/api/statistics/useGetStatisticsUsers";
import useGetStatisticsQuestions from "../../hooks/api/statistics/useGetStatisticsQuestions";
import useGetStatisticsCertificates from "../../hooks/api/statistics/useGetStatisticsCertificates";
import useGetStatisticsCompanies from "../../hooks/api/statistics/useGetStatisticsCompanies";
import useGetStatisticsIntegration from "../../hooks/api/statistics/useGetStatisticsIntegration";
import useGetStatisticsCourses from "../../hooks/api/statistics/useGetStatisticsCourses";
import useGetStatisticsCoursesProgress from "../../hooks/api/statistics/useGetStatisticsCoursesProgress";
import PieChartStatisticsChartJs from "../../components/PieChartStatistics/PieChartStatisticsChartJs";
import useGetStatisticsCoursesProgressGraph from "../../hooks/api/statistics/useGetStatisticsCoursesProgressGraph";
import { initialCourseFiltersData, initialFiltersData, initialOrders, initialOrdersBy, initialTeamFiltersData, initialTotalCount } from "./statisticsInitialData";
 
const StatisticsPage = () => {

  const companyID = useSelector((state) => state.sidebar.companyId);
  const userId = useSelector(state => state?.user?.info?.id);
  const { userRoleId } = useUserRole(companyID);

  const params = useParams();
  const courseProgressBlockRef = useRef(null);

  const t = useFindTranslations();

  const [tableTotalCount, setTableTotalCount] = useState(initialTotalCount);
  const [tags, setTags] = useState([]);
   
  const [tableOrdersBy, setTableOrdersBy] = useState(initialOrdersBy)
  const [tableOrders, setTableOrders] = useState(initialOrders)
  
   // Main tabs
   const [activeTab, setActiveTab] = useState(STAT_TAB_COURSES);
   const [graphLoading, setGraphLoading] = useState(true);

   // Charts tabs
   const [activeTabLeft, setActiveTabLeft] = useState(DATE_TAB_DAYLY);
   const [activeTabRight, setActiveTabRight] = useState(DATE_TAB_DAYLY);

   const [usersOnlineData, setUsersOnlineData] = useState({});
   const [usersRegistrationData, setUsersRegistrationData] = useState({});
   const [registrationDataTotal, setRegistrationDataTotal] = useState({});

   const [activeFilters, setActiveFilters] = useState(initialFiltersData);
   const [activeTeamFilters, setActiveTeamFilters] = useState(initialTeamFiltersData);
   const [activeCourseFilters, setActiveCourseFilters] = useState(initialCourseFiltersData);
   const [activeCourseGraphFilter, setActiveCourseGraphFilter] = useState(null);
   const [activeTagsFilter, setActiveTagsFilter] = useState('');

   const { setCurrentPage: setCurrentDropUserPage, totalUsers, 
      userList: dropdownUserList, 
      isUsersLoading: isDropdownUserLoading, 
      searchedValue: dropdownUserSearchedValue, 
      setSearchedValue: setDropdownUserSearchedValue 
   } = useGetAllUsersOfCompany(companyID);
      
   const lastUserInDropdownRef = useIntersectionObserver(
      () => {
      if (!isDropdownUserLoading && dropdownUserList?.length < totalUsers) {
         if(dropdownUserList?.length === 0) {
            setCurrentDropUserPage(1);
         } else {
            setCurrentDropUserPage((prev) => prev + 1);
         }
      }
      },
      [isDropdownUserLoading, totalUsers]
      );

   const onClickOnTab = (tab) => {
      setActiveTab(tab?.label);
   };

   useEffect(()=> {
      if(params?.tab) {
         const tab = STATISTICS_TABS?.find(t => +t?.id === +params?.tab)
         onClickOnTab(tab);
      }

      if(params?.courseId && courseProgressBlockRef.current) {
          courseProgressBlockRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [params])


   const clickActiveTabLeft = (tab) => {
      setActiveTabLeft(tab?.label);
   };

   const clickActiveTabRight = (tab) => {
      setActiveTabRight(tab?.label);
   };

   const agencyManager = useGetAgencies(userId);

   const lastAgencyRef = useIntersectionObserver(
      () => {
        if (!agencyManager.isAgenciesLoading && agencyManager.agenciesList?.length < agencyManager.totalAgencies) {
          if(agencyManager.agenciesList?.length === 0) {
            agencyManager.setCurrentPage(1);
          } else {
            agencyManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [agencyManager.isAgenciesLoading, agencyManager.totalAgencies]
    );

   const allTeamsManager = useGetAllTeams();

   const lastTeamRef = useIntersectionObserver(
      () => {
        if (!allTeamsManager.isTeamsLoading && allTeamsManager.teamsList?.length < allTeamsManager.totalTeams) {
          if(allTeamsManager.teamsList?.length === 0) {
            allTeamsManager.setCurrentPage(1);
          } else {
            allTeamsManager.setCurrentPage((prev) => prev + 1);
          }
        }
      },
      [allTeamsManager.isTeamsLoading, allTeamsManager.totalTeams]
    );

   const tranformCourseImage = (course) => course?.image && !course.image.includes('static/media/') ? course?.image
      : courseImg;

    //  all available to your courses
    const { courseList: allCoursesList, isCoursesLoading: isAllCoursesListLoading, refetchCourses: refetchAllCourses, totalCourses: allCoursesTortal, setCurrentPage: setAllCoursesPage, searchedValue: allCoursesSearchedValue, setSearchedValue: setAllCoursesSearchedValue } = useGetYourCoursesInCompany(companyID);
 
    const lastAllCourseRef = useIntersectionObserver(
       () => {
         if (!isAllCoursesListLoading && allCoursesList?.length < allCoursesTortal) {
           if(allCoursesList?.length === 0) {
            setAllCoursesPage(1);
           } else {
            setAllCoursesPage((prev) => prev + 1);
           }
         }
       },
       [isAllCoursesListLoading, allCoursesTortal]
     );

   const fetchUsersOnlineData = async() => {
      const res = await new StatisticsApi().getAllUsersOnline();
      if(res?.success?.data) {
         setUsersOnlineData(res?.success?.data);
      }
   }

   const fetchUsersRegistrationData = async() => {
      setGraphLoading(true);
      const res = await new StatisticsApi().getAllUsersGrowth();
      if(res?.success?.data) {
         setUsersRegistrationData(res?.success?.data);
      }
      setGraphLoading(false);
   }

   const getAllTags = async () => {
      const res = await new CourseApi().getAllTags();
      if(res?.success?.data) {
         setTags(res?.success?.data);
      } 
   }

   // COURSES TABLE
   const { 
      data: coursesStatisticsData, 
      lastItemRef: lastCourseRef, 
      searchedValue: coursesSearchedValue, 
      setSearchedValue: setCoursesSearchedValue,
      loading: isCoursesLoading 
   } = useGetStatisticsCourses(tableTotalCount, setTableTotalCount, activeTagsFilter, tableOrdersBy.courses, tableOrders.courses);

   // COURSES PROGRESS TABLE
   const { 
      data: coursesPogressStatisticsData, 
      lastItemRef: lastCourseProgressRef, 
      searchedValue: coursesProgressSearchedValue, 
      setSearchedValue: setCoursesProgressSearchedValue,
      loading: isCoursesProgressLoading 
   } = useGetStatisticsCoursesProgress(tableTotalCount, setTableTotalCount, activeCourseFilters?.coursesCourseFilter, null, tableOrdersBy.coursesProgress, tableOrders.coursesProgress);

   // COURSES PROGRESS GRAPH
   const { 
      data: coursesPogressGraphStatisticsData, 
      lastItemRef: lastCourseProgressGraphRef, 
      searchedValue: coursesProgressGraphSearchedValue, 
      setSearchedValue: setCoursesProgressGraphSearchedValue,
      loading: isCoursesProgressGraphLoading 
   } = useGetStatisticsCoursesProgressGraph(tableTotalCount, setTableTotalCount, activeCourseGraphFilter);
   

   // TESTS TABLE
   const { 
      data: testsStatisticsData, 
      lastItemRef: lastTestRef, 
      searchedValue: testsSearchedValue, 
      setSearchedValue: setTestsSearchedValue,
      loading: testsLoading 
   } = useGetStatisticsTests(tableTotalCount, setTableTotalCount, activeFilters, activeCourseFilters, tableOrdersBy.tests, tableOrders.tests);

   // USERS TABLE
   const { 
      data: statisitcsUsersData, 
      lastItemRef: lastStatisticsUserRef, 
      searchedValue: statisitcsUsersSearchedValue, 
      setSearchedValue: setStatisitcsUsersSearchedValue,
      loading: statisitcsUsersLoading 
   } = useGetStatisticsUsers(tableTotalCount, setTableTotalCount, activeFilters, activeCourseFilters, activeTeamFilters, tableOrdersBy.users, tableOrders.users);
   
   // QUESTIONS TABLE
   const { 
      data: questionsStatisticsData, 
      lastItemRef: lastQuestionRef, 
      searchedValue: questionsSearchedValue, 
      setSearchedValue: setQuestionsSearchedValue,
      loading: questionsLoading 
   } = useGetStatisticsQuestions(tableTotalCount, setTableTotalCount, activeFilters, activeCourseFilters, null, tableOrdersBy.questions, tableOrders.questions);

   // CERTIFICATES TABLE
   const { 
      data: certificatesStatisticsData, 
      lastItemRef: lastCertificateRef, 
      searchedValue: certificatesSearchedValue, 
      setSearchedValue: setCertificatesSearchedValue,
      loading: certificatesLoading 
   } = useGetStatisticsCertificates(tableTotalCount, setTableTotalCount, activeFilters, null, activeTeamFilters, tableOrdersBy.certificates, tableOrders.certificates);

   // COMPANIES TABLE
   const { 
      data: companiesStatisticsData, 
      lastItemRef: lastCompanyRef, 
      searchedValue: ratingSearchedValue, 
      setSearchedValue: setRatingSearchedValue,
      loading: companyRatingLoading 
   } = useGetStatisticsCompanies(tableTotalCount, setTableTotalCount, activeFilters, null, activeTeamFilters, tableOrdersBy.companies, tableOrders.companies);

   // INTEGRATION TABLE
   const { 
      data: integrationData, 
      lastItemRef: lastIntegrationRef, 
      searchedValue: integrationSearchedValue, 
      setSearchedValue: setIntegrationSearchedValue,
      loading: integrationLoading 
   } = useGetStatisticsIntegration(tableTotalCount, setTableTotalCount, activeFilters, null, activeTeamFilters, tableOrdersBy.integration, tableOrders.integration);

   const fetchUsersRegistrationDataForCompany = async(companyId) => {
      const res = await new StatisticsApi().getAllUsersGrowthForCompany(companyId);

      if(res?.success?.data) {
         setUsersRegistrationData(res?.success?.data);
      }
   }

   const fetchTotalData = async() => {
      const res = await new StatisticsApi().getTotalRegistrated();

      if(res?.success?.data) {
         setRegistrationDataTotal(res?.success?.data);
      }
   }

   useEffect(() => {
      fetchTotalData();
      fetchUsersOnlineData();
      fetchUsersRegistrationData();
      getAllTags();
   }, []);

   useEffect(() => {
      if(userRoleId && companyID && +userRoleId !== ROLE_ID_SUPER_ADMIN) {
         setActiveFilters({...initialFiltersData, integrationCompanyFilter: {id: companyID}});
      } else {
         setActiveFilters(initialFiltersData);
      }
      setActiveTeamFilters(initialTeamFiltersData);
      setActiveCourseFilters(initialCourseFiltersData);

      if(params?.courseId) {
         const course = { id: +params?.courseId, name: params?.courseName || 'Filter applied' }

         setActiveCourseFilters(prev => ({
            ...initialCourseFiltersData,
            coursesCourseFilter: course
         }))
      }
   }, [activeTab, userRoleId, companyID]);

   useEffect(() => {
      if (params?.courseId) {
          setActiveCourseGraphFilter((prev) => {
              if (prev !== coursesPogressStatisticsData[0]) {
                  return {
                     ...coursesPogressStatisticsData[0],
                     courseId: +params?.courseId,
                     name: params?.courseName
                  }
              }
              return prev;
          });
      }
  }, [params, coursesPogressStatisticsData]);
  
   
   useEffect(() => {
      if(activeFilters?.leftChartCompanyFilter?.id) {
         fetchUsersRegistrationDataForCompany(activeFilters?.leftChartCompanyFilter?.id)
      }

      if(activeFilters?.leftChartCompanyFilter?.name === 'All') {
         fetchUsersRegistrationData()
      }
   }, [activeFilters?.leftChartCompanyFilter]);

    const mappedQuestions = questionsStatisticsData?.map(questionsMap) || [];
    const mappedTests = testsStatisticsData?.map(testsMap) || [];
    const mappedCertificates = certificatesStatisticsData?.map(certificatesMap) || [];
    const mappedCompanies = companiesStatisticsData?.map(companiesMap) || [];
    const mappedIntegration = integrationData?.map(integrationMap) || [];
    const mappedUsers = statisitcsUsersData?.map(statisticsUsersMap) || [];
    const mappedCourses = coursesStatisticsData?.map(coursesMap) || [];
    const mappedCoursesProgress = coursesPogressStatisticsData?.map(coursesProgressMap) || [];
    
    const chartData = [
      ["Status", "value"],
      ["Complete", +activeCourseGraphFilter?.usersCompletedCourse],
      ["Ongoing", +activeCourseGraphFilter?.usersStartedCourse],
      ["Not started", +activeCourseGraphFilter?.usersNotStartedCourse],
      ["Failed", +activeCourseGraphFilter?.usersFailedCourse],
   ];

    const usersOnCourse = activeCourseGraphFilter?.activeUsersWithAccess;

   return (
      <MainLayout>
         <main className={styles.statistics_page}>
            <h2 className="page_title">{t?.returnTranslation('Statistics')}</h2>
            <StatisticsTabs t={t} activeTab={activeTab} clickOnTab={onClickOnTab} />

            {activeTab === STAT_TAB_PLATFORM && (
               <div className={styles.graph_wrapper}>
                  <LineChart
                     key={"left"}
                     t={t}
                     currentAmount={registrationDataTotal?.total?.totalUsers || 0}
                     activeTab={activeTabLeft}
                     clickActiveTab={clickActiveTabLeft}
                     companies={agencyManager?.agenciesList}
                     lastAgencyRef={lastAgencyRef}
                     activeFilter={activeFilters?.leftChartCompanyFilter}
                     setActiveFilter={(value)=> setActiveFilters(prev => ({
                        ...prev,
                        leftChartCompanyFilter: value
                     }))}
                     data={usersRegistrationData}
                  />
                  <LineChart
                     key={"right"}
                     t={t}
                     isGreen
                     currentAmount={registrationDataTotal?.total?.totalActiveUsers || 0}
                     activeTab={activeTabRight}
                     clickActiveTab={clickActiveTabRight}
                     activeFilter={activeFilters?.rightChartCompanyFilter}
                     setActiveFilter={(value)=> setActiveFilters(prev => ({
                        ...prev,
                        rightChartCompanyFilter: value
                     }))}
                     data={usersOnlineData}
                  />
               </div>
            )}

            {activeTab === STAT_TAB_COURSES && (
               <div className={styles.grade_wrapper}>
                  <div className={styles.table_wrapper}>
                     <StatisticsSubheader
                        companies={agencyManager?.agenciesList}
                        lastAgencyRef={lastAgencyRef}
                        searchedValue={coursesSearchedValue}
                        setSearchedValue={setCoursesSearchedValue}
                        leftText={t?.countingCourses}
                        leftSubText={`${tableTotalCount?.courses || 0} ${t?.coursesInTotal}`}
                        tags={tags}
                        isCourses
                        activeTagsFilter={activeTagsFilter}
                        setActiveTagsFilter={setActiveTagsFilter}
                        t={t}
                     />
                     <StatisticsTable
                        data={mappedCourses}
                        columns={COURSES_TABLE_HEADERS}
                        maxHeight={"45vh"}
                        lastElementRef={lastCourseRef}
                        searchedValue={coursesSearchedValue}
                        t={t}
                        onSetOrder={(order)=> {
                           setTableOrders((prev)=> ({
                              ...prev,
                              courses: order
                           }))  
                        }}
                        onSetOrderBy={(orderBy)=> {
                           setTableOrdersBy((prev)=> ({
                              ...prev,
                              courses: orderBy
                           }))  
                        }}
                        order={tableOrders?.courses}
                     />

                     {isCoursesLoading && 
                        <div className={styles.loaderWrapper}>
                           <SecondLoader size={"small"}/>
                        </div>
                      }
                  </div>

                  <div className={styles.two_tables_wrapper}>
                     <div className={cn(styles.table_wrapper, styles.two_tables_item)}>
                        <StatisticsSubheader
                           key={'questionsSubheader'}
                           companies={agencyManager?.agenciesList}
                           lastAgencyRef={lastAgencyRef}
                           courses={allCoursesList}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           lastAllCourseRef={lastAllCourseRef}
                           activeCourseFilter={activeCourseFilters?.questionsCourseFilter}
                           setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              questionsCourseFilter: value
                           }))}
                           activeFilter={activeFilters?.questionsCompanyFilter}
                           setActiveFilter={(value)=> setActiveFilters(prev => ({
                              ...prev,
                              questionsCompanyFilter: value
                           }))}
                           setSearchedValue={setQuestionsSearchedValue}
                           leftText={t?.resultsOfTasksInTheTests}
                           isSmall
                           t={t}
                        />
                        <StatisticsTable
                           key={'questionsTable'}
                           data={mappedQuestions}
                           columns={QUESTIONS_TABLE_HEADERS}
                           maxHeight={"45vh"}
                           maxWordsLength={20}
                           lastElementRef={lastQuestionRef}
                           t={t}
                           onSetOrder={(order)=> {
                              setTableOrders((prev)=> ({
                                 ...prev,
                                 questions: order
                              }))  
                           }}
                           onSetOrderBy={(orderBy)=> {
                              setTableOrdersBy((prev)=> ({
                                 ...prev,
                                 questions: orderBy
                              }))  
                           }}
                           order={tableOrders?.questions}
                        />

                        {questionsLoading && 
                           <div className={styles.loaderWrapper}>
                              <SecondLoader size={"small"}/>
                           </div>
                        }
                     </div>
                     <div className={cn(styles.table_wrapper, styles.two_tables_item)}>
                        <StatisticsSubheader
                           key={'testsSubheader'}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           courses={allCoursesList}
                           lastAllCourseRef={lastAllCourseRef}
                           activeCourseFilter={activeCourseFilters?.testsCourseFilter}
                           setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              testsCourseFilter: value
                           }))}
                           companies={agencyManager?.agenciesList}
                           lastAgencyRef={lastAgencyRef}
                           activeFilter={activeFilters?.testsCompanyFilter}
                           setActiveFilter={(value)=> setActiveFilters(prev => ({
                              ...prev,
                              testsCompanyFilter: value
                           }))}
                           setSearchedValue={setTestsSearchedValue}
                           leftText={t?.resultsOfTheTests}
                           isSmall
                           t={t}
                        />
                        <StatisticsTable
                           data={mappedTests}
                           columns={TESTS_TABLE_HEADERS}
                           maxHeight={"45vh"}
                           maxWordsLength={18}
                           key={'testsTable'}
                           lastElementRef={lastTestRef}
                           t={t}
                           onSetOrder={(order)=> {
                              setTableOrders((prev)=> ({
                                 ...prev,
                                 tests: order
                              }))  
                           }}
                           onSetOrderBy={(orderBy)=> {
                              setTableOrdersBy((prev)=> ({
                                 ...prev,
                                 tests: orderBy
                              }))  
                           }}
                           order={tableOrders?.tests}
                        />

                        {testsLoading && 
                           <div className={styles.loaderWrapper}>
                              <SecondLoader size={"small"}/>
                           </div>
                        }
                     </div>
                  </div>

                  <div ref={courseProgressBlockRef} className={styles.course_progress_block}>
                     <div className={cn(styles.table_wrapper, styles.course_progress_table)}>
                        <StatisticsSubheader
                           searchedValue={coursesProgressSearchedValue}
                           setSearchedValue={setCoursesProgressSearchedValue}
                           leftText={t?.returnTranslation('Activity on the course')}
                           leftSubText={`${tableTotalCount?.courses_progress || 0} ${t?.coursesInTotal}`}
                           t={t}
                           isProgressHeader
                           courses={allCoursesList}
                           allCoursesSearchedValue={allCoursesSearchedValue}
                           setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                           lastAllCourseRef={lastAllCourseRef}
                           activeCourseFilter={activeCourseFilters?.coursesCourseFilter}
                           setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              coursesCourseFilter: value
                           }))}
                        />
                        <StatisticsTable
                           data={mappedCoursesProgress}
                           columns={COURSES_PROGRESS_TABLE_HEADERS}
                           isProgressTable
                           maxHeight={"45vh"}
                           lastElementRef={lastCourseProgressRef}
                           searchedValue={coursesProgressSearchedValue}
                           t={t}
                           maxWordsLength={25}
                           onSetOrder={(order)=> {
                              setTableOrders((prev)=> ({
                                 ...prev,
                                 coursesProgress: order
                              }))  
                           }}
                           onSetOrderBy={(orderBy)=> {
                              setTableOrdersBy((prev)=> ({
                                 ...prev,
                                 coursesProgress: orderBy
                              }))  
                           }}
                           order={tableOrders?.coursesProgress}
                        />

                           {isCoursesProgressLoading && 
                              <div className={styles.loaderWrapper}>
                                 <SecondLoader size={"small"}/>
                              </div>
                           }
                     </div>
                     <div className={cn(styles.table_wrapper, styles.course_proogress_graph)}>
                        <div>

                     <p className={styles.subtitle}>{t?.returnTranslation('Activity on the course')}</p>
                     <DropdownWithImg
                           withoutImage
                           noNeedAllOption
                           lastItemRef={lastCourseProgressGraphRef}
                           data={coursesPogressGraphStatisticsData?.map((course)=> ({
                              ...course,
                              name: course?.courseName || '-',
                           }))}
                           allCoursesSearchedValue={coursesProgressGraphSearchedValue}
                           setAllCoursesSearchedValue={setCoursesProgressGraphSearchedValue}
                           placeholder={t?.selectCourse}
                           value={activeCourseGraphFilter}
                           onChange={(value)=> setActiveCourseGraphFilter(value)}
                           maxFrontHeight={"30px"}
                           searchNeeded
                           limit={40}
                        />
                        </div>
                        <div className={styles.chart_container_circle}>
                          <div className={styles.chart_extra_container}>
                           <PieChartStatisticsChartJs
                                 textInside={t?.returnTranslation("Users on course")} 
                                 chartData={chartData} 
                                 score={usersOnCourse} 
                              />
                          </div>
                          {!usersOnCourse && 
                           <div className={styles.emptyCircle}></div>
                          }
                        </div>

                        <div className={styles.colors}>
                           {COURSE_PASSING_COLORS?.map((item) => 
                              <div key={item?.name} className={styles.wrapper_dot}>
                                 <div style={{ backgroundColor: item?.color }} className={styles.dot}></div>

                                 <span>{t?.returnTranslation(item?.name)}</span>
                              </div>
                           )}
                        </div>
                        
                     </div>
                  </div>
               </div>
            )}

            {activeTab === STAT_TAB_SCORE && (
               <div className={styles.grade_wrapper}>
                  <div className={styles.table_wrapper}>
                     <StatisticsSubheader
                        key={'certificatesSubheader'}
                        companies={agencyManager?.agenciesList}
                        lastAgencyRef={lastAgencyRef}
                        activeFilter={activeFilters?.certificateCompanyFilter}
                        setActiveFilter={(value)=> setActiveFilters(prev => ({
                           ...prev,
                           certificateCompanyFilter: value
                        }))}
                        setSearchedValue={setCertificatesSearchedValue}
                        noTeam
                        leftText={t?.numberOfCertificatesIssued}
                        leftSubText={`${tableTotalCount?.certificates || 0} ${t?.certificatesInTotal}`}
                        t={t}
                     />
                     <StatisticsTable
                        key={'certificatesTable'}
                        data={mappedCertificates}
                        columns={CERTIFICATES_TABLE_HEADERS}
                        maxHeight={"45vh"}
                        lastElementRef={lastCertificateRef}
                        t={t}
                        onSetOrder={(order)=> {
                           setTableOrders((prev)=> ({
                              ...prev,
                              certificates: order
                           }))  
                        }}
                        onSetOrderBy={(orderBy)=> {
                           setTableOrdersBy((prev)=> ({
                              ...prev,
                              certificates: orderBy
                           }))  
                        }}
                        order={tableOrders?.certificates}
                     />

                     {certificatesLoading && 
                        <div className={styles.loaderWrapper}>
                           <SecondLoader size={"small"}/>
                        </div>
                     }
                  </div>
               </div>
            )}

            {activeTab === STAT_TAB_COMPANY && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'ratingSubheader'}
                   companies={agencyManager?.agenciesList}
                   lastAgencyRef={lastAgencyRef}
                   activeFilter={activeFilters?.ratingCompanyFilter}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                      ...prev,
                      ratingCompanyFilter: value
                   }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   activeTeamFilter={activeTeamFilters?.ratingTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      ratingTeamFilter: value
                   }))}
                   setSearchedValue={setRatingSearchedValue}
                   leftText={t?.successRatingAmongTheCompanies}
                   leftSubText={`${registrationDataTotal?.total?.totalUsers || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'ratingTable'}
                   tableFor={'company'}
                   data={mappedCompanies}
                   columns={RATING_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   lastElementRef={lastCompanyRef}
                   t={t}
                   onSetOrder={(order)=> {
                     setTableOrders((prev)=> ({
                        ...prev,
                        companies: order
                     }))  
                  }}
                  onSetOrderBy={(orderBy)=> {
                     setTableOrdersBy((prev)=> ({
                        ...prev,
                        companies: orderBy
                     }))  
                  }}
                  order={tableOrders?.companies}
                />

                  {companyRatingLoading && 
                     <div className={styles.loaderWrapper}>
                        <SecondLoader size={"small"}/>
                     </div>
                  }
             </div>
            }

            {activeTab === STAT_TAB_INTEGRUM && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'integrumSubheader'}
                   companies={agencyManager?.agenciesList}
                   lastAgencyRef={lastAgencyRef}
                   activeFilter={activeFilters?.integrationCompanyFilter}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                      ...prev,
                      integrationCompanyFilter: value
                   }))}
                   activeTeamFilter={activeTeamFilters?.integrationTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      integrationTeamFilter: value
                   }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   setSearchedValue={setIntegrationSearchedValue}
                   leftText="Users rating"
                   leftSubText={`${tableTotalCount?.integration || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'integrationTable'}
                   data={mappedIntegration}
                   tableFor={"integration"}
                   columns={INTEGRATION_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   lastElementRef={lastIntegrationRef}
                   minWidth
                   t={t}
                   onSetOrder={(order)=> {
                     setTableOrders((prev)=> ({
                        ...prev,
                        integration: order
                     }))  
                  }}
                  onSetOrderBy={(orderBy)=> {
                     setTableOrdersBy((prev)=> ({
                        ...prev,
                        integration: orderBy
                     }))  
                  }}
                  order={tableOrders?.integration}
                />

                  {integrationLoading && 
                     <div className={styles.loaderWrapper}>
                        <SecondLoader size={"small"}/>
                     </div>
                  }
             </div>
            }
            {activeTab === STAT_TAB_USERS && 
                <div className={styles.table_wrapper}>
                <StatisticsSubheader
                   key={'usersSubheader'}
                   courses={allCoursesList}
                   allCoursesSearchedValue={allCoursesSearchedValue}
                   setAllCoursesSearchedValue={setAllCoursesSearchedValue}
                   allUsersSearchedValue={dropdownUserSearchedValue}
                   setAllUsersSearchedValue={setDropdownUserSearchedValue}
                   lastAllCourseRef={lastAllCourseRef}
                   activeCourseFilter={activeCourseFilters?.usersCourseFilter}
                   setActiveCourseFilter={(value)=> setActiveCourseFilters(prev => ({
                              ...prev,
                              usersCourseFilter: value
                   }))}
                   activeTeamFilter={activeTeamFilters?.usersTeamFilter}
                   setActiveTeamFilter={(value)=> setActiveTeamFilters(prev => ({
                      ...prev,
                      usersTeamFilter: value
                   }))}
                   noCompanyFilter
                   dropdownUserList={dropdownUserList}
                   lastUserInDropdownRef={lastUserInDropdownRef}
                   activeFilter={activeFilters?.usersTabUser}
                   setActiveFilter={(value)=> setActiveFilters(prev => ({
                           ...prev,
                           usersTabUser: value
                     }))}
                   teams={allTeamsManager}
                   lastTeamRef={lastTeamRef}
                   setSearchedValue={setStatisitcsUsersSearchedValue}
                   leftText={t?.usersInCompany}
                   leftSubText={`${tableTotalCount?.users || 0} ${t?.usersInTotal}`}
                   t={t}
                />
                <StatisticsTable
                   key={'usersTable'}
                   data={mappedUsers}
                   rowClickable
                   columns={ activeCourseFilters?.usersCourseFilter?.id ?STATISTICS_USERS_TABLE_HEADERS_WITH_COURSE_FILTER : STATISTICS_USERS_TABLE_HEADERS}
                   maxHeight={"45vh"}
                   lastElementRef={lastStatisticsUserRef}
                   minWidth
                   tableFor={'users'}
                   t={t}
                   onSetOrder={(order)=> {
                     setTableOrders((prev)=> ({
                        ...prev,
                        users: order
                     }))  
                  }}
                  onSetOrderBy={(orderBy)=> {
                     setTableOrdersBy((prev)=> ({
                        ...prev,
                        users: orderBy
                     }))  
                  }}
                  order={tableOrders?.users}
                  activeCourseFilter={activeCourseFilters?.usersCourseFilter}
                />

                  {statisitcsUsersLoading && 
                     <div className={styles.loaderWrapper}>
                        <SecondLoader size={"small"}/>
                     </div>
                  }
             </div>
            }
         </main>
      </MainLayout>
   );
};

export default StatisticsPage;
