export const pathList = {
    signup: {
        path: '/signup'
    },
    login: {
        path: '/login'
    },
    home: {
        path: '/home'
    },
    studentHome: {
        path: '/student_home'
    },
    courses: {
        path: '/courses'
    },
    coursesWithFilter: {
        path: '/courses/:filterId'
    },
    coursesWithFilterFromHome: {
        path: '/courses/228/:filterId'
    },
    folder: {
        path: '/courses/folder/:id'
    },
    folder_settings: {
        path: '/courses/folder/:id/edit'
    },
    topic: {
        path: '/courses/folder/:folderId/topic/:topicId'
    },
    topic_settings: {
        path: '/courses/folder/:folderId/topic/:topicId/edit'
    },
    libraries: {
        path: '/libraries'
    },
    specialFolders: {
        path: '/special_folders'
    },
    surveys: {
        path: '/surveys'
    },
    knowledgeCheck: {
        path: '/knowledge_check'
    },
    knowledgeCheckActual: {
        path: '/knowledge_check/check/:id'
    },
    knowledgeCheckActualStudent: {
        path: '/knowledge_check/info/:id'
    },
    statistics: {
        path: '/statistics'
    },
    statisticsWithFilters: {
        path: '/statistics/:tab/:userId/:courseId/:courseName'
    },
    statistics_user_info: {
        path: '/statistics/user/:id'
    },
    applications: {
        path: '/applications'
    },
    users: {
        path: '/users'
    },
    team: {
        path: '/users/team/:id'
    },
    userProfile: {
      path: '/profile/:tab'
    },
    userProfileAdminView: {
      path: '/users/profile/:id'
    },
    userProfileAdminViewFromTeam: {
      path: '/users/team/:id/profile/:id'
    },
    usersRights: {
        path: '/user_rights'
    },
    variables: {
        path: '/variables'
    },
    agencies: {
        path: '/companies'
    },
    agencySettings: {
      path: '/companies/settings/:id'
    },
    coursesSettings: {
      path: '/courses/settings/:id'
    },
    lessonSettings: {
      path: '/courses/lesson_settings/:id'
    },
    lessonSettingsPlusEditLang: {
      path: '/courses/lesson_settings/:id/:courseId/default_lang/:defaultLang/:editLang/:is_few_langs'
    },
    coursesCreate: {
        path: '/courses/edit/:id/default_lang/:defaultLang/:is_few_langs'
    },
    coursesEditWithActiveLessonAndEditLang: {
      path: '/courses/edit/:id/default_lang/:defaultLang/:is_few_langs/:editLang/:activeLesson'
    },
    coursesEditOld: {
      path: '/courses/edit/:id'
    },
    coursesCreateWithModal: {
        path: '/courses/edit/:id/modal/:modalHash/:defaultLang/:is_few_langs'
    },
    coursesContent: {
        path: '/courses/course/:id/default_lang/:defaultLang/:is_few_langs'
    },
    coursesContentOld: {
        path: '/courses/course/:id'
    },
    coursesContentFromAdminView: {
        path: '/courses/course/:id/:statisticsUserId'
    },
    verified: {
      path: '/verified'
    },
    resetPassword: {
      path: '/reset-password/reset/:token'
    },
    languagesPage: {
      path: '/languages'
    },
    departmentsAndPositions: {
      path: '/departments_and_positions/:tab'
    },
    companyLanguagesPage: {
      path: '/languages/:company_id',
    },
};