import statisticsIcon from '../assets/images/curator/statistics.svg';
import newUsersIcon from '../assets/images/curator/new_users.svg';
import queriesIcon from '../assets/images/curator/queries.svg';
import passwordIcon from '../assets/images/profile_icons/lock_icon.svg';
import teamsIcon from '../assets/images/profile_icons/teams_icon.svg';
import userIcon from '../assets/images/profile_icons/user_icon.svg';
import usersIcon from '../assets/images/profile_icons/users_icon.svg';
import certificateIcon from '../assets/images/profile_icons/certificate_icon.svg';

import { transformRoleText } from '../utils/rolesHelper';
import { sortRoles } from '../utils/usersHelper';


export const USER_GENDER = {
    'man': 1,
    'woman': 2,
    'other': 3,
}

export const allowedStudentPages = [
   "/home",
   "/knowledge_check",
   "/courses",
   "/profile",
   "/companies",
 ];

 export const allowedStudentPathsStartsWith = [
   "/knowledge_check/info/", 
   "/courses/course/", 
   "/courses/folder/",
   "/profile/",
   "/courses/",
 ];

export const KNOWLEDGE_CHECK_DATE_OPTIONS = [
   { name: "For today", label: "for_today" },
   { name: "For yesterday", label: "for_yesterday" },
   { name: "In a week", label: "in_a_week" },
   { name: "In a month", label: "in_a_month" },
]

export const DEFAULT_ROLES = [
   {name: 'All', label: 'all'},
   {name: 'Guest', label: 'guest'},
   {name: 'Student', label: 'student'},
   {name: 'Curator', label: 'curator'},
   {name: 'Mentor', label: 'mentor'},
   {name: 'Admin', label: 'admin'},
]

export const DEFAULT_DATA_LIMIT = 10;
export const SMALLER_DATA_LIMIT = 8;
export const BIGGER_DATA_LIMIT = 3_000;
export const NOTIFICATION_API_CALL_INTERVAL = 120_000;

export const USER_STATUS_UNVERIFIED = 0; 
export const USER_STATUS_ACTIVE = 1; // is set when user is registered
export const USER_STATUS_INACTIVE = 2;
export const USER_STATUS_BANNED = 3;

export const ROLE_ID_SUPER_ADMIN = 1;
export const ROLE_ID_STUDENT = 2;
export const ROLE_ID_GUEST = 3;
export const ROLE_ID_ADMIN = 4;
export const ROLE_ID_MENTOR = 9;

export const BRANCH_ROLE_STUDENT = [
   {
       role: {
          id: ROLE_ID_STUDENT,
          name: 'ROLE_STUDENT'
       }
   }
 ]

export const PART_OF_MENTOR_NAME = 'MENTOR';
export const PART_OF_ADMIN_NAME = 'ADMIN';
export const PART_OF_CURATOR_NAME = 'CURATOR';

export const TABS_USER_PROFILE_ADMIN_VIEW = [
   "Access to materials",
   // "Surveys",
   "Knowledge check",   
   "Certificates",
   // "Statistics",
   // "Applications",
   "Teams"
 ];

export const requestStatusMapping = {
   1: 'waiting',
   2: 'accepted',
   3: 'declined',
 }

 export const REQUESTED_STATUS_REJECT = 3;
 export const REQUESTED_STATUS_ACCEPT = 2;
 export const REQUESTED_STATUS_PENDING = 1;

export const ROLES = [
   'ROLE_SUPER_ADMIN',
   'ROLE_STUDENT', 
   'ROLE_ADMIN', 
   'ROLE_EDITOR', 
   'ROLE_TEACHER',
   'ROLE_ANALYTIC',
   'ROLE_CURATOR'
];

 export const ALL_STUDENTS_TABLE_HEADERS = [
   "name",
   'email',
   "department",
   "position",
   "role",
   "was",
 ];

 export const PROFILE_TEAMS_TABLE_HEADERS = [
   "name",
   'participants',
   "role",
 ];

 export const PROFILE_CURATOR_TABLE_HEADERS = [
   "folder_name",
   'course_name',
 ];

 export const PROFILE_COMPANIES_TABLE_HEADERS = [
   "company_name",
   'courses',
   'participants',
   "role",
 ];

 export const ALL_STUDENTS_IN_TEAM_TABLE_HEADERS = [
   "name",
   "email",
   "position",
   "was",
 ];

 export const ALL_COURSES_IN_TEAM_TABLE_HEADERS = [
   "folder_name",
   "course_name",
 ];

 export const REQUESTED_STUDENTS_TABLE_HEADERS = [
   "name",
   "email",
   "position",
   "role",
   "was",
   "request"
 ];

 export const TEAMS_HEADERS = [
   "team",
   "department",
   "position",
   "amount",
 ];

 export const TEAMS_HEADERS_LESS_INFO = [
   "team",
   "department",
   "position",
 ];

 export const usersRightsData = [
   {
     name: "isHomework",
     title: "Homework",
     text: "The possibility of checking homework",
   },
   {
     name: "isAcceptedHomework",
     title: "Accepted homework",
     text: "Access to the accepted homework section with the ability to cancel the check",
   },
   // {
   //   name: "isViewingLessons",
   //   title: "Viewing lessons",
   //   text: "The ability to view lessons",
   // },
   {
     name: "isLessonModules",
     title: "Lesson modules",
     text: "The ability to customize and connect the appearance of the modules",
   },
   {
     name: "isAddingStudent",
     title: "Adding a student to the team",
     text: "The ability to add a new student manually or by list",
   },
   {
     name: "isShowDetails",
     title: "Show student contact details",
     text: "The ability to view student contact details",
   },
   {
     name: "isAddRoles",
     title: "Users",
     text: "The ability to add and remove users",
   },
   // {
   //   name: "isStatistics",
   //   title: "Statistics",
   //   text: "The ability to view analytical reports on student and employee performance",
   // },
   // {
   //   name: "isTags",
   //   title: "Tags",
   //   text: "Adding, editing, deleting tags",
   // },
 ];

 export const usersRightsDataFeedback = [
   {
     name: "isViewingRequests",
     title: "Viewing requests",
     text: "The ability to view requests from users",
   },
   {
     name: "isRespondToRequest",
     title: "Respond to requests",
     text: "The ability to respond to requests from users",
   },
 ];

 export const usersRightsDataSettings = [
   {
     name: "isAppearance",
     title: "Appearance",
     text: "The ability to edit the description and photo of the course",
   },
   // {
   //   name: "isStudentPermissions",
   //   title: "Student Permissions",
   //   text: "Editing student permissions settings",
   // },
   {
     name: "isRoleCapabilities",
     title: "Role Capabilities",
     text: "Edit rights settings for all roles",
   },
 ];

 export const pageStaticPermissionsInfoCustomRoles = [
   {
      name: "Working with content",
      items: [
         {
            permission_ids: [255, 194, 61, 121, 227],
            name: "Course creation",
            text: "Ability to create new courses",
            label: "isCourseCreation",
         },
         {
            permission_ids: [320, 321, 322, 324, 334, 335, 336, 337, 338, 323, 323, 329, 341, 342, 344, 345, 346, 347, 351, 385, 386, 388, 389, 393, 396, 391, 290, 270, 121, 123, 416, 258, 268, 274],
            name: "Course editing",
            text: "Ability to edit existing courses",
            label: "isCourseEditing",
         },
         {
            permission_ids: [551],
            name: "Public courses",
            text: "The ability to create new public courses or divide existing courses available to the user into public ones",
            label: "isCanChangePublicCourses",
         },
         {
            permission_ids: [259, 278, 279, 505, 282],
            name: "Adding users to courses",
            text: "Ability to add new users to existing courses",
            label: "isAddingUsersToCourses",
         },
         {
            permission_ids: [285],
            name: "Assigning course curators",
            text: "Ability to assign curators to courses",
            label: "isAssigningCourseCurators",
         },
         {
            permission_ids: [62, 316, 301],
            name: "Creating folders",
            text: "Ability to create new folders to organize courses and materials",
            label: "isCreatingFolders",
         },
         {
            permission_ids: [193],
            name: "Creating topics within folders",
            text: "Ability to add new topics inside existing folders",
            label: "isCreatingTopicsWithinFolders",
         },
         {
            permission_ids: [302, 319, 303, 259],
            name: "Granting folder access to users",
            text: "Ability to grant access to folders to other users",
            label: "isGrantingFolderAccess",
         },
         {
            permission_ids: [262],
            name: "Deleting courses",
            text: "Ability to delete courses",
            label: "isDeletingCourses",
         },
         {
            permission_ids: [544],
            name: "Hiding courses",
            text: "Ability to hide courses from other users",
            label: "isHidingCourses",
         },
         // {
         //    permission_ids: [270, 121, 123, 416],
         //    name: "Editing basic settings of courses",
         //    text: "Ability to make changes to the settings of courses",
         //    label: "isEditingCourseSettings",
         // },
         {
            permission_ids: [313],
            name: "Editing basic settings of folders",
            text: "Ability to make changes to the settings of folders",
            label: "isEditingFolderSettings",
         },
         {
            permission_ids: [540, 533, 232, 239, 236, 233, 530, 535, 234],
            name: "Viewing and checking homework",
            text: "Ability to view and check homework uploaded by users.",
            label: "isViewingAndCheckingHomework",
         },
         {
            permission_ids: [284],
            name: "Duplicate courses",
            text: "Ability to duplicate existing courses to create copies of them",
            label: "isDuplicateCourses",
         },
      ],
   },
   {
      name: "User Management",
      items: [
         {
            permission_ids: [505],
            name: "Changing user status on courses",
            text: "Ability to change the status of users on courses (active, paused, removed from course)",
            label: "isChangingUserStatus",
         },
         {
            permission_ids: [455],
            name: "User List Display",
            text: "Displaying lists of existing users within the company.",
            label: "isUserListDisplay",
         },
         {
            permission_ids: [278, 515],
            name: "Bulk status change on courses",
            text: "Ability to change the status of users on courses in bulk (active, paused, removed from course)",
            label: "isBulkStatusChange",
         },
         {
            permission_ids: [195, 196, 129],
            name: "Adding users to the platform",
            text: "Ability to add new students to the platform",
            label: "isAddingUsersToPlatform",
         },
         {
            permission_ids: [481, 258],
            name: "Changing roles for users",
            text: "Ability to modify the roles of users",
            label: "isChangingUserRoles",
         },
         {
            permission_ids: [493],
            name: "Blocking users",
            text: "Ability to block users",
            label: "isBlockingUsers",
         },
         {
            permission_ids: [454],
            name: "Bulk blocking of users",
            text: "Ability to block users in bulk",
            label: "isBulkBlockingUsers",
         },
         {
            permission_ids: [424, 440],
            name: "Creating Teams",
            text: "Ability to create new teams for collaboration on the platform",
            label: "isCreatingTeams",
         },
         {
            permission_ids: [425],
            name: "Team Display",
            text: "Displaying existing teams within the company",
            label: "isTeamDisplay",
         },
         {
            permission_ids: [435],
            name: "Removing users from teams",
            text: "Ability to remove users from existing teams",
            label: "isRemovingUsersFromTeams",
         },
         {
            permission_ids: [439, 198],
            name: "Mentorship",
            text: "Ability to assign mentors for a team",
            label: "isMentorship",
         },
         {
            permission_ids: [502, 483],
            name: "Removing users from the platform",
            text: "Ability to remove users from the platform",
            label: "isRemovingUsersFromPlatform",
         },
         {
            permission_ids: [469],
            name: "Bulk removing users from the platform",
            text: "Ability to remove users from the platform in bulk",
            label: "isBulkRemovingUsers",
         },
         {
            permission_ids: [294, 300, 383, 377, 381],
            name: "Departments & Positions",
            text: "Ability to manage company departments and positions via the \"Departments & Positions\" section",
            label: "isDepartmentsAndPositions",
         },
         {
            permission_ids: [398, 407, 373, 129],
            name: "Creating new roles on the platform",
            text: "Ability to create new roles and assign them to users on the platform",
            label: "isCreatingNewRoles",
         },
         {
            permission_ids: [298, 300, 381, 383, 503, 494],
            name: "Editing personal data of other users (name, email, position, department)",
            text: "Ability to edit personal information of other users such as name, email, position, department",
            label: "isEditingPersonalData",
         },
         {
            permission_ids: [33],
            name: '"Enter user account" Functionality',
            text: "Ability to log into other users' accounts to provide technical support or configure settings",
            label: "isEnterUserAccount",
         },
         {
            permission_ids: [468],
            name: "Viewing other users' profiles",
            text: "Ability to view other users' profiles on the platform",
            label: "isViewingUserProfiles",
         },
      ],
   },
   {
      name: "Global Settings",
      items: [
         {
            permission_ids: [240, 208],
            name: "Creating a new company",
            text: "Ability to create new companies within the platform",
            label: "isCreatingNewCompany",
         },
         {
            permission_ids: [250, 248, 247, 251, 253],
            name: "Editing settings of existing companies",
            text: "Ability to modify the settings of existing companies",
            label: "isEditingCompanySettings",
         },
         {
            permission_ids: [254, 481],
            name: "Adding users to different companies",
            text: "Ability to add existing platform users to various companies",
            label: "isAddingUsersToCompanies",
         },
         {
            permission_ids: [197, 444, 445],
            name: "Access to company language settings",
            text: "Ability to choose which available languages will or will not be displayed for company users",
            label: "isAccessingLanguageSettings",
         },
         {
            permission_ids: [483],
            name: "Removing users from a company",
            text: "Ability to remove users from companies registered on the platform",
            label: "isRemovingUsersFromCompany",
         },
         {
            permission_ids: [411, 410, 413],
            name: 'Access to the "By Platform" tab in statistics',
            text: "Ability to view registration and activity statistics of users on the platform",
            label: "isAccessingByPlatformTab",
         },
         {
            permission_ids: [415, 417, 418, 416],
            name: 'Access to the "By Courses" tab in statistics',
            text: "Ability to view user performance statistics for specific courses",
            label: "isAccessingByCoursesTab",
         },
         {
            permission_ids: [419],
            name: 'Access to the "By Score" tab in statistics',
            text: "Ability to view statistics of certificates received on the platform",
            label: "isAccessingByScoreTab",
         },
         {
            permission_ids: [420],
            name: 'Access to the "By Company" tab in statistics',
            text: "Ability to view the performance of all users in a selected company",
            label: "isAccessingByCompanyTab",
         },
         {
            permission_ids: [422, 423],
            name: 'Access to the "By Users" tab in statistics',
            text: "Ability to view detailed progress of individual users across all their courses",
            label: "isAccessingByUsersTab",
         },
         {
            permission_ids: [421],
            name: 'Access to the "Integration" tab in statistics',
            text: "Ability to view the percentage of user integration",
            label: "isAccessingIntegrationTab",
         },
         {
            permission_ids: [197, 442, 444, 445, 247],
            name: "Access to the Localization system",
            text: "Ability to add new languages to the LMS by using the variable localization system, and delete existing languages",
            label: "isAccessingLocalizationSystem",
         },
      ],
   },
];

export const allCustomPermissions = pageStaticPermissionsInfoCustomRoles?.flatMap(item => Object.values(item?.items)) || [];

 // label should be the same as inputsData in UsersRight page
 export const pageStaticPermissionsInfo = [
   {permission_ids: [533], name: 'Homework', label: 'isHomework'},
   {permission_ids: [530, 532], name: 'Accepted homework', label: 'isAcceptedHomework'},
   // {permission_ids: [348, 325], name: 'Viewing Lessons' , label: 'isViewingLessons'},
   {permission_ids: [320, 321, 322, 324, 334, 335, 336, 337, 338, 323, 329, 341, 342, 344, 345, 346, 347, 351, 385, 386, 388, 389, 393, 396, 391, 290], name: 'Lesson modules' , label: 'isLessonModules'},
   {permission_ids: [259, 440], name: 'Adding a student' , label: 'isAddingStudent'},
   {permission_ids: [141, 468], name: 'Show student contact details' , label: 'isShowDetails'},
   {permission_ids: [41, 43, 129, 398, 407], name: 'Role' , label: 'isAddRoles'},
   // {permission_ids: [283], name: 'Tags' , label: 'isTags'},
   {permission_ids: [270, 274, 275, 277], name: 'Appearance' , label: 'isAppearance'},
   // {permission_ids: [82, 83], name: 'Student Permissions' , label: 'isStudentPermissions'},
   {permission_ids: [373, 398, 407], name: 'Role Capabilities' , label: 'isRoleCapabilities'},
]

export const BASIC_INFO_TAB = '1';
export const POSITION_TAB = '2';
export const ACTIVITY_TAB = '3';
export const CERTIFICATES_TAB = '4';
export const CHANGE_PASSWORD_TAB = '5';

export const PROFILE_BASIC_SETTINGS_SUBMENU = [
   {
      id: BASIC_INFO_TAB,
      name: "Basic info",
      el: "basic_info",
      icon: userIcon
   },
   {
      id: POSITION_TAB,
      name: "Position",
      el: "position",
      icon: teamsIcon
   },
   {
      id: ACTIVITY_TAB,
      name: "Activity",
      el: "activity",
      icon:  usersIcon   
   },
   {
      id: CERTIFICATES_TAB,
      name: "Certificates",
      el: "certificates",
      icon: certificateIcon    
   },
]


export const PROFILE_SAFETY_SETTINGS_SUBMENU = [
   {
      id: CHANGE_PASSWORD_TAB,
      name: "Change password",
      el: "change_password",
      icon: passwordIcon
   },
   // {
   //    id: 2,
   //    name: "Social media",
   //    el: "social_media",
   // }
]

export const MALE = 1;
export const FEMALE = 2;
export const OTHER_GENDER = 3;

export const GENDERS = [
   {type: MALE, name: 'Male'},
   {type: FEMALE, name: 'Female'},
   {type: OTHER_GENDER, name: 'Not specified'},
]

export const CURATOR_MODAL_LIST = [
   {id: 1, title: 'Statistics and progress', desc: 'Track statistics and progress of course participants', icon: statisticsIcon },
   {id: 2, title: 'New users', desc: 'Add participants to the course at any time', icon: newUsersIcon },
   {id: 3, title: 'Answers to queries', desc: 'Respond to requests from course participants', icon: queriesIcon },
]

export const returnCompanyFirstRoleName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id === +currentCompanyId)?.sort(sortRoles);

   return superAdminRole?.[0]?.role?.name || filteredByCompanyRoles?.[0]?.role?.name;
}

// !! that's excluded companies
const returnFilteredCompanyFirstRoleName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id !== currentCompanyId)?.sort(sortRoles);
   
   return superAdminRole?.[0]?.role?.name || filteredByCompanyRoles?.[0]?.role?.name;
}

const returnFilteredCompanyFirstCompanyName = (roles, currentCompanyId) => {
   const superAdminRole = roles?.filter(role => role?.role?.id === ROLE_ID_SUPER_ADMIN);
   const filteredByCompanyRoles = roles?.filter(role => role?.branch?.company?.id !== currentCompanyId);

   return superAdminRole?.[0]?.branch?.company?.name || filteredByCompanyRoles?.[0]?.branch?.company?.name;
}

export const mappedExcludedUsers = (user, currentCompanyId) => ({
   id: user?.id,
   role: transformRoleText(returnFilteredCompanyFirstRoleName(user?.userBranchRoles, currentCompanyId), returnFilteredCompanyFirstCompanyName(user?.userBranchRoles, currentCompanyId)) || 'Guest',
   name: user?.firstName || user?.lastName ? `${user.firstName || ''} ${user.lastName || ''}` : user.email,
   avatar: user?.avatar,
   department: user?.department,
   position: user?.position
})